import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, SubTitle} from "chart.js";
import {Bar, Pie} from "react-chartjs-2";
import {PRIMARY_COLOUR} from "placementt-core";
import {stringToRGBColor} from "../Util/util";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, SubTitle);


export function BarChart({title, subtitle, data={}}:{title?: string, subtitle?: string, data?: {[key: string]: {value?: number, color?: string, callback?: () => void}}}) {
    const formattedData = {
        labels: Object.keys(data),
        datasets: [
            {
                data: Object.values(data).map((item) => item?.value),
                backgroundColor: Object.values(data).map((item) => `${item?.color || PRIMARY_COLOUR}70`),
                borderColor: Object.values(data).map((item) => item?.color || PRIMARY_COLOUR),
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 24,
                    family: "nunito",
                    weight: "lighter",
                },
                color: "black",
                padding: {
                    bottom: subtitle ? 0 : 10,
                },
            },
            subtitle: {
                display: Boolean(subtitle),
                text: subtitle,
                padding: {
                    bottom: 20,
                },
            },
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                },
            },
            x: {
                ticks: {
                    font: {
                        family: "nunito",
                        weight: "lighter",
                    },
                    color: "black",
                },
            },
        },
        onClick: function(evt: any, element: any) {
            if (element.length > 0) {
                const item = Object.values(data)[element[0].index];
                item.callback && item.callback();
            }
        },
    };

    return (
        <>
            <Bar style={{marginTop: "21px"}} height={"200px"} data={formattedData} options={options as any} />
        </>
    );
}

export function PieChart({title, data={}}:{title: string, subtitle?: string, data?: {[key: string]: {value?: number, callback?: () => void}}}) {
    const formattedData = {
        labels: Object.keys(data),
        datasets: [
            {
                data: Object.values(data).map((item) => item?.value),
                backgroundColor: Object.keys(data).map((key) => stringToRGBColor(key)),
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 24,
                    family: "nunito",
                    weight: "lighter",
                },
                color: "black",
            },
        },
        onClick: function(evt: any, element: any) {
            if (element.length > 0) {
                const item = Object.values(data)[element[0].index];
                item.callback && item.callback();
            }
        },
    };

    return (<Pie title={title} data={formattedData} options={options as any}/>);
}
