import {Avatar, Button, Checkbox, Chip, Collapse, Divider, FormControlLabel, FormGroup, Grid, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, MenuItem, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import Page from "../../../Components/Page";
import {Popup} from "../../../Components/Popup";
import {useContext, useEffect, useState} from "react";
import {Alumni as AlumniData, CohortData, ExternalActivity, InstituteData, InstituteProviderContactUpload, PRIMARY_COLOUR, PlacementListing, ProviderContactData, QueryObject, SchoolData, camelCaseToNormal, capitaliseWords, convertDate, countryList, executeCallable, getMaximumStudentsFromListingCohorts, getRandomNumber, storage, useAlumniPaginator, useExecuteCallableJob, useInstituteProviderContactsHandler, useProviderContactPaginator} from "placementt-core";
import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import SpreadsheetUploader from "../../../Components/SpreadsheetUploader";
import {OrganisationContext, UserContext} from "../../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import ControlledSwitch from "../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import {InfoTableCell} from "../../../Util/styledComponents";
import {ProviderAddress} from "../../Shared/ViewPlacement";
import DeletePopup from "../../../Components/DeletePopup";
import {where} from "firebase/firestore";
import PlacementMap from "../../Shared/PlacementMap";
import TutorialPage from "../../Shared/TutorialPage";
import {getDownloadURL, ref} from "firebase/storage";
import {useNavigate} from "react-router-dom";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Close, Delete} from "@mui/icons-material";
import Card from "../../../Components/Card";
import {LoadingButton} from "../../../Components/LoadingButton";
import Form from "../../../Components/Form";
import {BarChart} from "../../../Components/Charts";
import ExternalActivities from "../../Shared/ExternalActivities";
import DataViewer from "../../../Components/DataViewer";
import useWindowDimensions from "../../../Util/util";
import AlumniConversations, {AlumniConversationsPage} from "../AlumniConversations";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import Alert from "../../../Components/Alert";
// import PlacementMap from "../../Shared/PlacementMap";


export default function Network() {
    const user = useContext(UserContext);
    // This is required so that once the user dismisses the staffPlacements tutorial it doesn't
    // immediately show the activity one. We want this state to not update even when the user does.
    const [completedTutorial] = useState(user.dismissedTutorials?.includes("instituteStaffPlacements"));
    const subNavItems = [
        false && {
            label: "Overview",
            route: "overview",
            element: <NetworkOverview/>,
        },
        false && {
            label: "Events",
            route: "events",
            element: <Events/>,
        },
        {
            label: "Employers",
            route: "employers",
            element: <ProviderContactsList/>,
        },
        {
            label: "Placement Listings",
            route: "listings",
            element: <PlacementListingsList/>,
        },
        {
            label: "Activities",
            route: "activities",
            element: <ActivitiesOverview/>,
        },
        false && {
            label: "Alumni",
            route: "alumni",
            element: <Alumni/>,
        },
        false && {
            route: "alumni/conversations",
            element: <AlumniConversationsPage/>,
        },
        {
            label: "Map",
            route: "map",
            element: <PlacementsMap/>,
        }].filter((i) => i) as SubNavBarItems;


    return (
        <Page
            title="Network"
            subtitle={"View and manage your network of employers and alumni."}
            metaTitle="My Network | Placementt"
            noGutters
            fullHeight>
            <TutorialPage pageId="instituteStaffPlacements"/>
            {completedTutorial && <TutorialPage pageId="instituteStaffActivities"/>}
            <SubNavBar items={subNavItems}/>
        </Page>);
}

function PlacementListingsList() {
    const instituteDetails = useContext(OrganisationContext).details as InstituteData;
    const schools = useContext(OrganisationContext).schools as {[key: string]: SchoolData}|undefined;
    const cohorts = useContext(OrganisationContext).cohorts as {[key: string]: CohortData}|undefined;

    const [totalPlacementCount, setTotalPlacementCount] = useState<number>();

    const user = useContext(UserContext);


    const firebaseQuery = new FirebaseQuery();


    useEffect(() => {
        firebaseQuery.getCount(["placementListings"], [where(`savedBy.${user.oId}.exists`, "==", true)]).then(setTotalPlacementCount);
    }, []);

    return (
        <Page grid>
            <Grid xs={12} item>
                <Alert severity="info" >To allow students to view these listing, go to the "Listings" section of a cohort and select which listings you would like to make available.</Alert>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack height={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: PRIMARY_COLOUR, borderRadius: "20px", padding: "10px 20px", color: "white"}}>
                    <Typography sx={{fontWeight: "bold"}} color={"white !important"}>Total</Typography>
                    <Typography sx={{fontWeight: "bold", color: "white !important"}}>{totalPlacementCount}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} item>
                <DataViewer
                    columns={["business", "contact", "email", "cohorts", instituteDetails.package === "institutes-two" ? "schools" : undefined, "maxStudents"].filter((i) => i) as string[]}
                    columnTemplate={{
                        contact: (_:any, row: PlacementListing) => <strong>{row.contactForename} {row.contactSurname}</strong>,
                        business: (_:any, row: PlacementListing) => row.name,
                        email: (_:any, row: PlacementListing) => row.providerEmail,
                        cohorts: (_:any, row: PlacementListing) => <Chip color="primary" label={Object.keys(row.savedBy?.[user.oId].cohorts || {}).length === 0 ? "None" : Object.keys(row.savedBy?.[user.oId].cohorts || {}).length === 1 ? cohorts?.[Object.keys(row.savedBy?.[user.oId].cohorts || {})[0]].name : `${Object.keys(row.savedBy?.[user.oId].cohorts || {}).length} cohorts`}/>,
                        schools: (_:any, row: PlacementListing) => <Chip sx={{color: (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].color : undefined, borderColor: (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].color : undefined}} label={(row.savedBy?.[user.oId].MATschools || []).length === 0 ? "All schools" : (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].name : `${(row.savedBy?.[user.oId].MATschools || []).length} schools`}/>,
                        maxStudents: (_:any, row: PlacementListing) => row.concurrentPlacements || getMaximumStudentsFromListingCohorts(row.savedBy?.[user.oId].cohorts),
                    }}
                    data={[{
                        path: ["placementListings"],
                        where: [[`savedBy.${user.oId}.exists`, "==", true]],
                    } as QueryObject]}>
                </DataViewer>
            </Grid>
        </Page>);
}

function ProviderContactsList() {
    const [popupActive, setPopupActive] = useState(false);
    const [viewPlacementPopup, setViewPlacementPopup] = useState<ProviderContactData|false>(false);
    const [viewPlacementContent, setViewPlacementContent] = useState<ProviderContactData>();
    const [externalProviderUploadPopup, setExternalProviderUploadPopup] = useState(false);
    const [externalProviderUploadCopied, setExternalProviderUploadCopied] = useState(false);
    const [deleteProviderContactsPopup, setDeleteProviderContactsPopup] = useState<ProviderContactData|boolean>(false);
    const [selectedProviderContacts, setSelectedProviderContacts] = useState<"All"|{[key: string]: ProviderContactData}>();
    const [placementTableKey, setPlacementTableKey] = useState(getRandomNumber(0, 100000));

    const instituteDetails = useContext(OrganisationContext).details as InstituteData;
    const schools = useContext(OrganisationContext).schools as {[key: string]: SchoolData}|undefined;
    const activities = useContext(OrganisationContext).activities as {[key: string]: ExternalActivity}|undefined;

    const [uploaderSchool, setUploaderSchool] = useState<string>();

    const [allowExternalProviderUploads, setAllowExternalProviderUploads] = useState(instituteDetails.externalProviderUploads);

    const [totalPlacementCount, setTotalPlacementCount] = useState<number>();
    const [contactsNoActivities, setContactsNoActivities] = useState<number>();

    const user = useContext(UserContext);

    const {uploadProviderContacts, alert} = useInstituteProviderContactsHandler({...{user}});

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (viewPlacementPopup) {
            setViewPlacementContent(viewPlacementPopup);
            return;
        }
    }, [viewPlacementPopup]);

    useEffect(() => {
        firebaseQuery.getCount(["providerContacts"], [where(`savedBy.${user.oId}.exists`, "==", true)]).then(setTotalPlacementCount);
        firebaseQuery.getCount(["providerContacts"], [where(`savedBy.${user.oId}.activities`, "==", null), where(`savedBy.${user.oId}.exists`, "==", true)]).then(setContactsNoActivities);
    }, []);

    const {execute} = useExecuteCallableJob({user});

    const updateExternalProviderUploads = async () => {
        return await firebaseQuery.update(["institutes", user.oId], {externalProviderUploads: !instituteDetails.externalProviderUploads});
    };

    // const toggleVisibility = async (visibility: "show"|"hide") => {
    //     if (!selectedPlacements) return;
    //     console.log("SEC", selectedPlacements);

    //     if (selectedPlacements !== "All") {
    //         await Promise.all(Object.keys(selectedPlacements).map(async (savedPlacement) => {
    //             return await firebaseQuery.update(["savedPlacements", savedPlacement], {listed: visibility === "show"});
    //         }));
    //         setPlacementTableKey(getRandomNumber(0, 10000));
    //         return;
    //     }

    //     await firebaseQuery.getDocsWhere("placementListings", [where(`savedBy.${user.oId}.exists`, "==", true)]).then(async (docs) => {
    //         const placements = docs as {[key: string]: PlacementListing};
    //         await Promise.all(Object.keys(placements).map(async (placement) => {
    //             return await firebaseQuery.update(["placementListings", placement], {[]: visibility === "show"});
    //         }));
    //         setPlacementTableKey(getRandomNumber(0, 10000));
    //     });
    // };

    // const resendProviderEmails = async () => {
    //     await executeCallable("placementListing-resendInstituteEmails", {items: selectedPlacements});

    //     return {severity: "success", message: "Reminder sent."};
    // };
    const resendActivityButton = <LoadingButton onClick={async () => await executeCallable("providerContacts-resendActivityInviteEmail", {oId: user.oId})} color="inherit" sx={{alignSelf: "center !important"}} noFlexGrow text="Resend activity invite email"/>;
    console.log("ACT", activities);
    return (
        <Page grid>
            <Grid xs={12} item>
                <Collapse in={Boolean(contactsNoActivities && contactsNoActivities > 0 && Object.values(activities || {}).filter((a) => a.provider).length)}>
                    <Alert severity="info" action={
                        <Stack alignSelf={"center"}>
                            {resendActivityButton}
                            {instituteDetails.activityInvitesSent && <Typography variant="caption">Email last sent: {convertDate(instituteDetails.activityInvitesSent, "visual") as string}</Typography>}
                        </Stack>
                    }>
                        <Typography fontWeight={"bold"}>{contactsNoActivities} contact{contactsNoActivities !== 1 ? "s" : ""} ha{contactsNoActivities !== 1 ? "ve" : "s"} not yet consented to any activities.</Typography>
                        <Typography variant="subtitle2">This may be due to you setting up after they joined.</Typography>
                    </Alert>
                </Collapse>
            </Grid>
            <Grid xs={12} md={8} item>
                <DataViewer
                    columns={["contact", "employer", "email", "status", "activities", instituteDetails.package === "institutes-two" ? "schools" : undefined, "created"].filter((i) => i) as string[]}
                    columnTemplate={{
                        contact: (_:any, row: ProviderContactData) => <strong>{row.contactForename} {row.contactSurname}</strong>,
                        employer: (_:any, row: ProviderContactData) => row.name,
                        email: (_:any, row: ProviderContactData) => row.providerEmail,
                        status: (_:any, row: ProviderContactData) => <Chip variant="filled" color={row.savedBy?.[user.oId].status === "approved" ? "success" : row.savedBy?.[user.oId].status === "uploaded" ? "warning" : undefined} label={capitaliseWords(camelCaseToNormal(row.savedBy?.[user.oId].status))}/>,
                        activities: (_:any, row: ProviderContactData) => {
                            const rowActivities = row.savedBy?.[user.oId].activities;
                            const activityCount = rowActivities?.length;
                            const activityColor = activityCount === 1 ? activities?.[rowActivities?.[0] as string]?.color : undefined;
                            return (<Chip sx={{color: activityColor, borderColor: activityColor}} label={activityCount === 1 ? activities?.[rowActivities?.[0] as string]?.title : (activityCount || 0) > 1 ? `${activityCount} activities` : "No activities"}/>);
                        },
                        schools: (_:any, row: ProviderContactData) => <Chip sx={{color: (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].color : undefined, borderColor: (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].color : undefined}} label={(row.savedBy?.[user.oId].MATschools || []).length === 0 ? "All schools" : (row.savedBy?.[user.oId].MATschools || []).length === 1 ? schools?.[(row.savedBy?.[user.oId].MATschools || [])[0]].name : `${(row.savedBy?.[user.oId].MATschools || []).length} schools`}/>,
                        created: (date: string) => convertDate(date, "visual"),
                        // listed: (value:boolean, row: PlacementListing) => <Chip color={row.savedPlacement.listed ? "primary" : undefined} label={row.savedPlacement.listed ? "True" : "False"}/>
                    }}
                    viewRows
                    onItemClick={(_, row) => setViewPlacementPopup(row as ProviderContactData)}
                    hook={(e) => useProviderContactPaginator({data: e.data as QueryObject[], user: user, view: e.view, filters: e.filters})}
                    actions={<Button onClick={() => setPopupActive(true)}>Add Employers</Button>}
                    key={placementTableKey}
                    setSelectedRows={(e) => setSelectedProviderContacts(e as "All"|{[key:string]: ProviderContactData})}>
                    {<IconButtonPop color="error" title={"Delete contact"+(selectedProviderContacts && (selectedProviderContacts === "All" || Object.keys(selectedProviderContacts).length > 1) ? "s" : "")} onClick={() => setDeleteProviderContactsPopup(true)}><Delete/></IconButtonPop>}
                    {/* <IconButtonPop onClick={() => toggleVisibility("show")} title="Show to students"><Visibility/></IconButtonPop>
                    <IconButtonPop onClick={() => toggleVisibility("hide")} title="Hide from students"><VisibilityOff/></IconButtonPop> */}
                    {/* <LoadingButton sx={{display: "inline-block !important"}} noFlexGrow onClick={async () => await resendProviderEmails()} text="Resend provider emails"/> */}
                </DataViewer>
            </Grid>
            <Grid xs={12} md={4} item>
                <Stack>
                    <Card title={<Typography variant={"h5"}>Overview</Typography>}>
                        <List>
                            <ListItem sx={{marginBottom: "20px"}}>
                                <ListItemText primaryTypographyProps={{fontWeight: "bold"}} sx={{marginRight: 2.5}} primary="Allow external business uploads"/>
                                <ListItemSecondaryAction>
                                    {!allowExternalProviderUploads ? <ControlledSwitch name="externalProviderConsent" onCheck={() => setExternalProviderUploadPopup(true)}/> : <Button onClick={() => setExternalProviderUploadPopup(true)}>View</Button>}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                                <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Total"/>
                                <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                                    {totalPlacementCount}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Active"/>
                                <ListItemSecondaryAction>
                                    0
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Reported"/>
                                <ListItemSecondaryAction>
                                    0
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem sx={{background: "#00000010", borderRadius: "20px"}}>
                                <ListItemText primary="Blocked"/>
                                <ListItemSecondaryAction>
                                    0
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Card>
                    <ExternalActivities md={12}/>
                </Stack>
            </Grid>
            <Popup maxWidth={"lg"} fullWidth open={popupActive} onClose={() => setPopupActive(false)} title={"Add Employer"}>
                {instituteDetails.package === "institutes-two" && <Stack>
                    {uploaderSchool ?
                        <List sx={{maxWidth: "550px", m: "auto", alignSelf: "center", border: `2px solid ${schools?.[uploaderSchool].color || PRIMARY_COLOUR}`, borderRadius: "8px"}}><ListItem>
                            {typeof schools?.[uploaderSchool].image === "string" && <ListItemAvatar><Avatar sx={{height: "50px", width: "50px"}} src={schools[uploaderSchool].image || ""}/></ListItemAvatar>}
                            <ListItemText primaryTypographyProps={{ml: 2, pr: 4, color: schools?.[uploaderSchool].color || PRIMARY_COLOUR, fontWeight: "bold", fontSize: 18}} primary={schools?.[uploaderSchool].name}/>
                            <ListItemSecondaryAction><IconButtonPop responsive={false} title="Remove" onClick={() => setUploaderSchool(undefined)}><Close/></IconButtonPop></ListItemSecondaryAction>
                        </ListItem></List> :
                        <Dropdown sx={{marginTop: "8px !important"}} label={"Select school (Optional)"} onChange={(e) => setUploaderSchool(e.target.value)} value={uploaderSchool}>
                            {Object.entries(schools || {}).map(([k, sch]) => <MenuItem value={k}>{sch.name}</MenuItem>)}
                        </Dropdown>}
                    <Divider/>
                </Stack>}
                <SpreadsheetUploader
                    cellTypes={{country: ["dropdown", countryList, "unitedKingdom"]}}
                    alert={alert}
                    noNewColumns
                    onSubmit={(e) => uploadProviderContacts(e as InstituteProviderContactUpload[], uploaderSchool).then((e) => e && setPopupActive(false))}
                    defaultCols={["business", "forename", "surname", "email", "phone", "addressOne", "addressTwo", "city", "postcode", "country"]}/>
            </Popup>
            <DeletePopup open={Boolean(deleteProviderContactsPopup)} onClose={() => setDeleteProviderContactsPopup(false)} title={"contact"} onDelete={async () => execute("providerContacts-delete", {data: {selectedContacts: selectedProviderContacts}})}>
                <Typography>Delete contact{(selectedProviderContacts && (selectedProviderContacts === "All" || Object.keys(selectedProviderContacts || {}).length > 1) ? "s" : "")}?</Typography>
            </DeletePopup>
            <ProviderContactDetailsPopup reloadTable={() => setPlacementTableKey(getRandomNumber(0, 10000))} open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(false)} providerContact={viewPlacementContent} onDeletePopupToggled={() => setDeleteProviderContactsPopup(viewPlacementContent || false)}/>
            <Popup open={externalProviderUploadPopup} onClose={() => {
                setExternalProviderUploadPopup(false);
                setExternalProviderUploadCopied(false);
            }} title={"Enable External Employer Submissions"}>
                <Stack>
                    <Typography>Allow businesses to join your network by sharing the link below.</Typography>
                    <Typography>Businesses can follow this link to submit their details and select activities they are interested to engage in.</Typography>
                    {allowExternalProviderUploads && <Stack direction={"row"} spacing={0} alignItems={"center"}>
                        <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/joinDatabase/${user.oId}`}/>
                        <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                            navigator.clipboard.writeText(`https://placementt.co.uk/joinDatabase/${user.oId}`);
                            setExternalProviderUploadCopied(true);
                        }}>{externalProviderUploadCopied ? "Coped!" : "Copy"}</Button>
                    </Stack>}
                    <LoadingButton color={allowExternalProviderUploads ? "error" : "primary"} onClick={() => updateExternalProviderUploads().then(() => {
                        setExternalProviderUploadPopup(!allowExternalProviderUploads && true);
                        setAllowExternalProviderUploads((a) => !a);
                        setExternalProviderUploadCopied(false);
                    })} text={allowExternalProviderUploads ? "Disable provider submissions" : "Enable provider submissions"}/>
                </Stack>
            </Popup>
        </Page>);
}

function ActivitiesOverview() {
    return (
        <Page grid>
            <Grid item md={8} xs={12}>
                <ExternalActivityNumbersBarGraph/>
            </Grid>
            <ExternalActivities type="provider" md={4}/>
        </Page>
    );
}

export function ProviderContactDetailsPopup({open, onClose, providerContact, providerContactId}:{open: boolean, onClose: () => void, providerContactId?: string, providerContact?: ProviderContactData, insurance?:boolean, onApprove?: () => Promise<void>, onDeletePopupToggled?: () => void, reloadTable?: () => void}) {
    const institute = useContext(OrganisationContext).details as InstituteData;
    const [rejectELIPopup, setRejectELIPopup] = useState(false);
    const [eliPopupOpen, setEliPopupOpen] = useState(false);
    const [eliURL, setELIURL] = useState<string>();
    // const [concurrentPopupOpen, setConcurrentPopupOpen] = useState(false);
    const [fProviderContact, setFProviderContact] = useState<ProviderContactData|undefined>();

    const user = useContext(UserContext);
    // const firebaseQuery = new FirebaseQuery();


    useEffect(() => {
        setFProviderContact(providerContact);
        if (user.userType !== "Staff") return;
        const storageRef = ref(storage, `insurance/${providerContactId}.pdf`);
        getDownloadURL(storageRef).then((file) => {
            setELIURL(file);
        }).catch(() => {
            setELIURL(undefined);
        });
    }, [providerContact]);

    // const toggleVisibility = async () => {
    //     if (!fPlacement?.savedPlacement?.id) return;
    //     await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {listed: !fPlacement?.savedPlacement?.listed});
    //     setFPlacement((p) => {
    //         if (!p) return;
    //         const newP = {...p};
    //         if (!newP.savedPlacement) return newP;

    //         newP.savedPlacement.listed = !newP.savedPlacement.listed;
    //         return newP;
    //     });
    //     reloadTable && reloadTable();
    //     return;
    // };

    // const setConcurrency = async (e: {concurrentPlacements: number}) => {
    //     if (!fPlacement?.savedPlacement?.id) return;
    //     await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {concurrentPlacements: e.concurrentPlacements});
    //     setFPlacement((p) => {
    //         if (!p) return;
    //         const newP = {...p};
    //         if (!newP.savedPlacement) return newP;

    //         newP.savedPlacement.concurrentPlacements = e.concurrentPlacements;
    //         return newP;
    //     });
    //     return;
    // };

    // const clearConcurrency = async () => {
    //     if (!fPlacement?.savedPlacement?.id) return;
    //     await firebaseQuery.update(["savedPlacements", fPlacement?.savedPlacement.id], {concurrentPlacements: deleteField()});
    //     setFPlacement((p) => {
    //         if (!p) return;
    //         const newP = {...p};
    //         if (!newP.savedPlacement) return newP;

    //         newP.savedPlacement.concurrentPlacements = undefined;
    //         return newP;
    //     });
    // };

    const acceptELI = async () => {
        if (user.userType !== "Staff") return;
        await executeCallable("insurance-approve", {providerContactId: providerContactId});
        setRejectELIPopup(false);
        setEliPopupOpen(false);
    };

    const rejectELI = async ({reason}:{reason: string}) => {
        if (user.userType !== "Staff") return;
        await executeCallable("insurance-reject", {reason: reason, instituteName: institute.name});
        setRejectELIPopup(false);
        setEliPopupOpen(false);
    };

    return (
        <>
            <Popup
                title={"View placement"}
                {...{open, onClose}}
                fullWidth
                maxWidth={"lg"}>
                {fProviderContact && <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            {/* user.userType === "Staff" && placement.insurance === "Verifying" && <Alert severity='info' action={<Button size={'small'} color={'inherit'} onClick={() => setEliPopupOpen(true)}>View</Button>}>Verify Employer's liability insurance.</Alert>*/}
                            <Table size="small" sx={{maxWidth: "550px", zIndex: 1}}>
                                <TableBody>
                                    <TableRow>
                                        <InfoTableCell>Provider name</InfoTableCell>
                                        <InfoTableCell>{fProviderContact.name}</InfoTableCell>
                                        <InfoTableCell></InfoTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <InfoTableCell>Provider email</InfoTableCell>
                                        <InfoTableCell>{fProviderContact.providerEmail}</InfoTableCell>
                                        <InfoTableCell></InfoTableCell>
                                    </TableRow>
                                    {/* {user.userType === "Staff" && <TableRow>
                                        <InfoTableCell>Listed to students</InfoTableCell>
                                        <InfoTableCell><Chip color={fPlacement?.savedPlacement?.listed ? "primary" : undefined} label={fPlacement?.savedPlacement?.listed ? "True" : "False"}/></InfoTableCell>
                                        <InfoTableCell><LoadingButton onClick={toggleVisibility} text={fPlacement?.savedPlacement?.listed ? "Hide" : "Show"}/></InfoTableCell>
                                    </TableRow>}
                                    {user.userType === "Staff" && <TableRow>
                                        <InfoTableCell>Max concurrent placements</InfoTableCell>
                                        <InfoTableCell>{fPlacement.savedPlacement?.concurrentPlacements || "Unset"}</InfoTableCell>
                                        <InfoTableCell><Button onClick={() => setConcurrentPopupOpen(true)}>Change</Button></InfoTableCell>
                                    </TableRow>} */}
                                </TableBody>
                            </Table>
                            <Divider sx={{marginBottom: "5px"}} />
                            {/* <Popup title={"Maximum concurrent placements"} open={concurrentPopupOpen} onClose={() => setConcurrentPopupOpen(false)}>
                                <Stack>
                                    <Typography>Set the maximum number of placements that can be active at one time.</Typography>
                                    <Typography variant="caption">Note, this is a preliminary feature, so will limit all placements currently in the workflow, regardless of date. If you would like us to improve this feature, please get in touch.</Typography>
                                    <Form onSubmit={(e) => setConcurrency(e as {concurrentPlacements: number})}>
                                        <InputGroup type={"number"} value={fPlacement.savedPlacement?.concurrentPlacements} name={"concurrentPlacements"} label={"Max placements"} min={0} required/>
                                    </Form>
                                    <LoadingButton onClick={clearConcurrency} text="Clear" variant="outlined" color={"inherit"}/>
                                </Stack>
                            </Popup> */}
                        </Stack>
                        {/* {Boolean(fPlacement.contactType) && <Alert severity="info">To apply for this placement, {
                            fPlacement.contactType === "phone" ? `phone ${fPlacement.providerPhone}` :
                                fPlacement.contactType === "post" ? "send your application by post" :
                                    fPlacement.contactType === "website" && fPlacement.webpage ? `go to ${fPlacement.webpage}` :
                                        `email ${fPlacement.providerEmail}`
                        }</Alert>} */}
                        <ProviderAddress placement={fProviderContact}/>
                        {/* {fPlacement.questions && <ProviderGuidance guidance={fPlacement.questions}/>} */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            {/* <PreviousPlacementsAtProviderGraph placementId={fPlacement.id}/> */}
                        </Stack>
                    </Grid>
                </Grid>}
            </Popup>
            {user.userType === "Staff" && <><Popup
                open={eliPopupOpen}
                onClose={() => setEliPopupOpen(false)}
                maxWidth={false}
                cardSx={{maxWidth: "700px", width: "calc(100vw - 10px)"}}
                title={"Employer's Liability Insurance"}
                actions={
                    <LoadingButton width='max-content'>
                        <Button variant='contained' onClick={acceptELI}>Accept</Button>
                        <Button color='error' onClick={async () => {
                            setRejectELIPopup(true); return;
                        }}>Reject</Button>
                    </LoadingButton>
                }>
                <object data={eliURL} type="application/pdf" width="100%" height="500px">
                    <p>Error loading insurance. Please reload.</p>
                </object>
            </Popup>
            <Popup title={"Reject insurance"} open={rejectELIPopup} onClose={() => setRejectELIPopup(false)}>
                <Typography>Rejecting this employers liability insurance will require them to reupload. You can check back here once it is checked.</Typography>
                <Form onSubmit={async (e) => {
                    rejectELI(e as {reason:string}); return;
                }}>
                    <InputGroup label="Rejection reason" name={"reason"} required/>
                </Form>
            </Popup></>}
        </>
    );
}


function PlacementsMap() {
    return (
        <Page fullHeight noGutters>
            <PlacementMap fullscreen={false} title="Search placements" type="internal"/>
        </Page>);
}


export function PreviousPlacementsAtProviderGraph({placementId}:{placementId: string}) {
    const [placements, setPlacements] = useState<{[year: string]: {value: number}}>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    useEffect(() => {
        const currentYear = new Date().getFullYear();

        for (let i = currentYear - 4; i <= currentYear; i++) {
            const firstDate = `${i}-01-01`;
            const lastDate = `${i}-12-31`;

            firebaseQuery.getCount(["placements"], [where("oId", "==", user.oId), where("placementId", "==", placementId), where("startDate", ">=", firstDate), where("startDate", "<=", lastDate)]).then((count) => setPlacements((p) => ({...p, [i]: {value: count}})));
        }
    }, []);

    function sortYearsByDate(years: { [year: string]: { value: number } }): { [year: string]: { value: number } } {
        // Convert the object to an array of entries, sort by the year, and convert back to an object
        const sortedEntries = Object.entries(years)
            .sort(([yearA], [yearB]) => parseInt(yearA) - parseInt(yearB)); // Sort by year in ascending order

        // Convert sorted entries back to an object
        return Object.fromEntries(sortedEntries);
    }

    return (<BarChart title="Previous placements" subtitle="Previous placements your students have attended." data={placements ? sortYearsByDate(placements) : undefined}/>);
}

export function ExternalActivityNumbersBarGraph() {
    const [counts, setCounts] = useState<{[activity: string]: {color?: string, value: number}}>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const activities = useContext(OrganisationContext).activities as {[key: string]: ExternalActivity};

    useEffect(() => {
        Promise.all(Object.entries(activities).map(async ([key, activity]) => {
            return [activity.title, {color: activity.color, value: await firebaseQuery.getCount(["providerContacts"], [where(`savedBy.${user.oId}.exists`, "==", true), where(`savedBy.${user.oId}.activities`, "array-contains", key)])}] as [string, {color?: string, value: number}];
        })).then((c) => setCounts(Object.fromEntries(c)));
    }, []);

    return (<BarChart title="Employer Activities" subtitle="View how many employers have consented to your activities" data={counts}/>);
}


function Alumni() {
    const [alumniCount, setAlumniCount] = useState<number>();
    const [toggleAlumniPopupActive, setToggleAlumniPopupActive] = useState(false);
    const [addAlumniPopup, setAddAlumniPopup] = useState(false);

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const schools = useContext(OrganisationContext).schools as {[key: string]: SchoolData};
    const institute = useContext(OrganisationContext).details as InstituteData;
    const {width} = useWindowDimensions();
    const [editSchoolAlumniAcceptance, setEditSchoolAlumniAcceptance] = useState<string>();
    const [uploadAlert, setUploadAlert] = useState<{
        severity: "warning" | "error" | "success" | "info";
        msg: string;
    }>();
    const [uploaderSchool, setUploaderSchool] = useState<string>();

    const schoolsAcceptingAlumni = institute.package === "institutes-two" ? Object.entries(schools || {}).filter(([, school]) => school.acceptingAlumni) : (institute.acceptingAlumni || false);
    const anyAcceptingAlumni = schoolsAcceptingAlumni === true || (Array.isArray(schoolsAcceptingAlumni) && schoolsAcceptingAlumni.length);

    useEffect(() => {
        firebaseQuery.getCount(["alumni"], [where("oId", "==", user.oId)]).then(setAlumniCount);
    }, []);

    const toggleAlumniAcceptance = async (e: {[key: string]: boolean}) => {
        if (!editSchoolAlumniAcceptance) {
            await firebaseQuery.update(["institutes", user.oId], e as Partial<InstituteData>);
        } else {
            await firebaseQuery.update(["schools", editSchoolAlumniAcceptance], e as Partial<SchoolData>);
        }
        setTimeout(() => {
            setEditSchoolAlumniAcceptance(undefined);
        }, 500);
    };

    const uploadAlumni = async (e: {forename: string, surname: string, email: string}[]) => {
        console.log("alumni", e);
        if (!uploaderSchool && institute.package === "institutes-two") {
            setUploadAlert({severity: "error", msg: "Please select a school to associate with these students."});
            return false;
        }
        if (!e.every((alumni) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(alumni.email))) {
            setUploadAlert({severity: "error", msg: "Ensure all emails are formatted correctly."});
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (!addAlumniPopup) {
            setUploadAlert(undefined);
            setUploaderSchool(undefined);
        }
    }, [addAlumniPopup]);

    useEffect(() => {
        if (uploaderSchool) {
            setUploadAlert(undefined);
        }
    }, [uploaderSchool]);

    if (institute.package === "institutes-two" && !schools) return null;

    return (
        <Page metaTitle="Alumni | Placementt" grid>
            <Grid xs={12} md={8} item>
                <Grid container minHeight={"70.75px !important"}>
                    <Grid item xs={12} md={8}>
                        <Stack height={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: PRIMARY_COLOUR, borderRadius: "20px", padding: "10px 20px", color: "white"}}>
                            <Typography sx={{fontWeight: "bold"}} color={"white !important"}>{(schoolsAcceptingAlumni === false || (typeof schoolsAcceptingAlumni === "object" && !schoolsAcceptingAlumni.length)) ? "You are not currently accepting new alumni" : (schoolsAcceptingAlumni === true ? "Currently accepting alumni" : `Currently accepting alumni in ${schoolsAcceptingAlumni.length} school${schoolsAcceptingAlumni.length === 1 ? "" : "s"}`)}</Typography>
                            <Button onClick={() => setToggleAlumniPopupActive(true)} color={"white"}>{(schoolsAcceptingAlumni === false || (typeof schoolsAcceptingAlumni === "object" && !schoolsAcceptingAlumni.length)) ? "Activate" : "View"}</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack height={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: "#00000010", borderRadius: "20px", padding: "10px 20px"}}>
                            <Typography>Total alumni </Typography>
                            <Typography>{alumniCount}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <DataViewer
                            sx={{height: "400px"}}
                            key={"Alumni"}
                            id={"alumni"}
                            columns={["name", institute.package === "institutes-two" ? "school": "", "email", "startYear", "endYear"].filter((i) => i.length)}
                            columnTemplate={{
                                name: (val:any, row: AlumniData) => (`${row.forename} ${row.surname}`),
                                school: (val:any, row: AlumniData) => (row.schoolId ? schools[row.schoolId]?.name : "Unknown school"),
                            }}
                            filterNames={undefined}
                            title={"Your alumni"}
                            actions={<Button variant="contained" onClick={() => setAddAlumniPopup(true)}>Add alumni</Button>}
                            disableSelection
                            hook={() => useAlumniPaginator({user, view: "list", ql: 10})}
                            onSearch
                            listViewRenderer={(key: string, item: AlumniData) => <ListItemButton key={key} divider id={key} onClick={() => null}>
                                <ListItemText
                                    primary={<Stack spacing={0}>
                                        {width <= 500 && item.schoolId && <Chip label={schools[item.schoolId]?.name} sx={{width: "max-content", borderColor: schools[item.schoolId].color || PRIMARY_COLOUR, color: schools[item.schoolId].color || PRIMARY_COLOUR, margin: "8px !important"}}/>}
                                        <strong>{item.forename} {item.surname}</strong>
                                    </Stack>}
                                    secondary={`${item.startYear} - ${item.endYear}`}/>
                                {width > 500 && <ListItemSecondaryAction sx={{height: "100%"}}>
                                    {item.schoolId && <Chip label={schools[item.schoolId]?.name} sx={{width: "max-content", borderColor: schools[item.schoolId]?.color || PRIMARY_COLOUR, color: schools[item.schoolId].color || PRIMARY_COLOUR, margin: "8px !important"}}/>}
                                </ListItemSecondaryAction>}
                            </ListItemButton>
                            }/>
                        <Popup maxWidth={"lg"} open={addAlumniPopup} onClose={() => setAddAlumniPopup(false)} title={"Add alumni"}>
                            {institute.package === "institutes-two" && <Stack>
                                {uploaderSchool ?
                                    <List sx={{maxWidth: "550px", m: "auto", alignSelf: "center", border: "2px solid #0000006e", borderRadius: "8px"}}><ListItem>
                                        {typeof schools[uploaderSchool].image === "string" && <ListItemAvatar><Avatar sx={{height: "50px", width: "50px"}} src={schools[uploaderSchool].image || ""}/></ListItemAvatar>}
                                        <ListItemText primaryTypographyProps={{ml: 2, pr: 4, color: schools[uploaderSchool].color, fontWeight: "bold", fontSize: 18}} primary={schools[uploaderSchool].name}/>
                                        <ListItemSecondaryAction><IconButtonPop responsive={false} title="Remove" onClick={() => setUploaderSchool(undefined)}><Close/></IconButtonPop></ListItemSecondaryAction>
                                    </ListItem></List> :
                                    <Dropdown label={"Select school"} onChange={(e) => setUploaderSchool(e.target.value)} value={uploaderSchool}>
                                        {Object.entries(schools).map(([k, sch]) => <MenuItem value={k}>{sch.name}</MenuItem>)}
                                    </Dropdown>}
                                <Divider/>
                            </Stack>}
                            <SpreadsheetUploader
                                noNewColumns
                                alert={uploadAlert}
                                onSubmit={(e) => uploadAlumni(e as {forename: string, surname: string, email: string}[]).then((e) => e && setAddAlumniPopup(false))}
                                defaultCols={["forename", "surname", "email"]}/>
                        </Popup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} md={4} item>
                <Stack>
                    {(anyAcceptingAlumni || alumniCount) && <AlumniConversations/>}
                    <ExternalActivities md={12} type="alumni"/>
                </Stack>
            </Grid>
            <Popup title={`${(schoolsAcceptingAlumni === false || (typeof schoolsAcceptingAlumni === "object" && !schoolsAcceptingAlumni.length)) ? "Activate" : "Edit"} alumni submissions`} open={toggleAlumniPopupActive} onClose={() => setToggleAlumniPopupActive(false)} key={"toggleAlumniPopujp"}>
                <Typography>Select whether new alumni can join your network.</Typography>
                <Form onSubmit={(e) => toggleAlumniAcceptance(e as {institute: boolean}|{[school: string]: boolean})}>
                    <List>
                        {typeof schoolsAcceptingAlumni === "boolean" ? <ListItem sx={{marginBottom: "20px"}}>
                            <ListItemText
                                primaryTypographyProps={{fontWeight: "bold"}}
                                sx={{marginRight: 2.5}}
                                primary="Allow alumni uploads"
                                secondary={schoolsAcceptingAlumni && <Link color={institute.color} target={"_blank"} href={`https://placementt.co.uk/alumniRequest/${user.oId}`}>View submission link</Link>}/>
                            <ListItemSecondaryAction>
                                <Button sx={{"color": institute.color, "borderColor": institute.color, "&:hover": {borderColor: institute.color, backgroundColor: `${institute.color}20`}}}>{schoolsAcceptingAlumni ? "Edit" : "Activate"}</Button>
                                {/* <ControlledSwitch name="institute" checked={schoolsAcceptingAlumni}/> */}
                            </ListItemSecondaryAction>
                        </ListItem> : Object.entries(schools).map(([schoolId, school]) =>
                            <ListItem sx={{marginBottom: "20px"}}>
                                {school.image && <ListItemAvatar>
                                    <Avatar sx={{height: "50px", width: "50px", mr: 2}} alt="School picture" src={school.image} />
                                </ListItemAvatar>}
                                <ListItemText
                                    primaryTypographyProps={{fontWeight: "bold", color: school.color}}
                                    sx={{marginRight: 2.5}}
                                    primary={school.name}
                                    secondary={school.acceptingAlumni && <Link color={school.color} target={"_blank"} href={`https://placementt.co.uk/alumniRequest/${user.oId}/${schoolId}`}>View submission link</Link>}/>
                                <ListItemSecondaryAction>
                                    <Button onClick={() => setEditSchoolAlumniAcceptance(schoolId)} sx={{"color": school.color, "borderColor": school.color, "&:hover": {borderColor: school.color, backgroundColor: `${school.color}20`}}}>{school.acceptingAlumni ? "Edit" : "Activate"}</Button>
                                    {/* <ControlledSwitch name={schoolId} checked={school.acceptingAlumni}/> */}
                                </ListItemSecondaryAction>
                            </ListItem>)}
                    </List>
                </Form>
            </Popup>
            <Popup title={`Configure ${editSchoolAlumniAcceptance && schools[editSchoolAlumniAcceptance].name+" "}alumni`} open={Boolean(editSchoolAlumniAcceptance)} onClose={() => setEditSchoolAlumniAcceptance(undefined)}>
                <Form onSubmit={async (e) => await toggleAlumniAcceptance(e as {[key: string]: boolean})} submitText="Save">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"acceptingAlumni"} name={"acceptingAlumni"} defaultChecked={(editSchoolAlumniAcceptance && schools[editSchoolAlumniAcceptance].acceptingAlumni) as boolean}/>} label={"Enable alumni"}/>
                    </FormGroup>
                    <Divider/>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"approveAlumni"} name={"approveAlumni"} defaultChecked={(editSchoolAlumniAcceptance && schools[editSchoolAlumniAcceptance].approveAlumni) as boolean}/>} label={"Approve new alumni"}/>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"shareAlumni"} name={"shareAlumni"} defaultChecked={(editSchoolAlumniAcceptance && schools[editSchoolAlumniAcceptance].shareAlumni) as boolean}/>} label={"Ask alumni to allow sharing of their details across your schools."}/>
                    </FormGroup>
                </Form>
            </Popup>
        </Page>
    );
}


function NetworkOverview() {
    const navigate = useNavigate();

    return (
        <Page grid>
            <Card grid xs={12} md={8} title={"Calendar"}></Card>
            <Grid item container xs={12} md={4} direction={"column"}>
                <Card grid title={"Overview"}>
                    <Button variant="contained" onClick={() => navigate("../events/new")}>Create an event!</Button>
                </Card>
                <ExternalActivities/>
            </Grid>
        </Page>
    );
}

function Events() {
    return (
        <Page grid metaTitle="Events | Placementt">
            Text
        </Page>
    );
}
