import {ArrowBack} from "@mui/icons-material";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, ListItemButton, ListItemIcon, ListItemText, MenuItem, Stack, Typography} from "@mui/material";
import {LoadingButton} from "../../../Components/LoadingButton";
import {PRIMARY_COLOUR, PlacementQuestions, PlacementReviewDetails, executeCallable, uploadFiles} from "placementt-core";
import FadeInBox from "../../../Components/FadeInBox";
import Form from "../../../Components/Form";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {useEffect, useState} from "react";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import UploadProviderRiskAssessment from "./UploadProviderRiskAssessment/UploadProviderRiskAssessment";

import UploadProviderDbsCheck from "./UploadProviderDbsCheck/UploadProviderDbsCheck";

type Params = {
    visible: boolean,
    onComplete?: (e: {[key:string]: unknown}) => any,
    back?: () => void,
    uid?: string,
    providerId?: string,
    pId?: string,
    placement?: PlacementReviewDetails,
    instituteName?: string,
    completedSections?: {details?: boolean, eli?: boolean, forms?: boolean, questions?: boolean, riskAssessment?: boolean, dbsCheck?: boolean},
    onCompleteSection?: (section: "details" | "eli" | "forms" | "questions" | "riskAssessment" | "dbsCheck", data?: {
        [key: string]: unknown;
    }) => void
}

export default function UploadPlacementQuestions({visible, onComplete, back, placement, uid, pId, instituteName, completedSections, onCompleteSection}: Params) {
    const [page, setPage] = useState<"activities"|"questionsOne"|"questionsTwo"|"advertisePlacement"|"consentPage"|"eventsConsentPage"|"submitPlacement">("questionsOne");
    const [questions, setQuestions] = useState<PlacementQuestions>();
    const [dressCodeOther, setDressCodeOther] = useState(false);
    const [placementId, setPlacementId] = useState(placement?.placementId);
    const [riskAssessmentLoading, setRiskAssessmentLoading] = useState(false);
    const [dbsCheckLoading, setDbsCheckLoading] = useState(false);
    const [mapConsent, setMapConsent] = useState<boolean|undefined>(placement?.mapConsent);
    const [eventsConsent, setEventsConsent] = useState<boolean|undefined>(placement?.eventsConsent);
    const [selectedActivities, setSelectedActivities] = useState<string[]>(placement?.consentedActivities || Object.keys(placement?.instituteActivities || {}).length ? Object.entries(placement?.instituteActivities || {}).filter(([, v]) => v.activityType === "workExperience").map(([k]) => k) : []);

    useEffect(() => {
        setPage("questionsOne");
    }, [visible]);

    // useEffect(() => {
    //     if (page === "activities") return;
    //     setPage("activities");
    // }, [page]);

    useEffect(() => {
        if (!placement) return;
        if (placement.questions) {
            setQuestions(placement.questions);
        }
        if (placement.placementId) {
            setPlacementId(placement.placementId);
        }
    }, [placement]);

    const onSubmitQuestions = async (e?: {mapConsent?: boolean, eventsConsent?: boolean}) => {
        const uploadQuestionObject = {
            title: questions?.title,
            questions: questions,
            mapConsent: e?.mapConsent || mapConsent,
            eventsConsent: e?.eventsConsent || eventsConsent,
            activities: Object.keys(placement?.instituteActivities || {}).length ? selectedActivities : null,
        };

        if (!placement) {
            onComplete && await onComplete(uploadQuestionObject);
            return;
        }
        await executeCallable("placement-submitProviderReview", {pId: pId, stage: placement.currentStage, questions: uploadQuestionObject, providerId: placement.providerId, placementListingId: placementId})
            .catch((e) => {
                throw e;
            }).then(() => {
                const toSubmit:{[key:string]: unknown} = {...uploadQuestionObject, placementId: placementId};
                onComplete && onComplete(toSubmit);
                return;
            });
    };

    console.log("EP", placement?.existingPlacements);

    const selectPlacementRiskAssessmentOrDbsCheck = async ({e, setLoading, type, placement}:{e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setLoading: (value: React.SetStateAction<boolean>) => void, type: "riskAssessment"|"dbsCheck", placement: PlacementReviewDetails}) => {
        setLoading(true);

        if (!placement.existingPlacements?.[e.target.value][`${type}Type`]) {
            setLoading(false);
            setPlacementId(e.target.value);
            return;
        }
        if (placement.existingPlacements?.[e.target.value][`${type}Type`] === "file") {
            const fileData = await executeCallable(`${type}-duplicate`, {oldId: e.target.value, newId: pId}).catch((a) => {
                console.error("ERROR", a);
                setLoading(false);
                setPlacementId(e.target.value);
                return;
            });

            if (!fileData?.data) {
                console.log("No response");
                return;
            }

            await uploadFiles(fileData, `${type}s/${pId}.pdf`, "application/pdf");
        }

        await executeCallable(`${type}-add`, {
            uploadType: placement.existingPlacements?.[e.target.value][`${type}Type`],
            [type]: placement.existingPlacements?.[e.target.value][`${type}Data`],
            pId: pId,
            providerId: placement.providerId})
            .then(() => {
                onCompleteSection && onCompleteSection(type);
            });
        setLoading(false);
    };

    return (
        <>
            <FadeInBox card cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={back} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Placement details</Typography>]} visible={visible && page === "questionsOne"}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form functionType='sync' onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage("questionsTwo");
                }} submitText={"Next"}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Basic details</Typography>
                                </Grid>
                                {placement && placement.existingPlacements && Object.keys(placement.existingPlacements).length > 0 && <>
                                    <Grid item xs={12}>
                                        <Alert severity="info">We've remembered previous placements so you don't have to duplicate the details. Select from the dropdown below or fill out the questions manually</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Dropdown label={"Select placement"} onChange={async (e) => {
                                            placement.existingPlacements && setQuestions(placement.existingPlacements[e.target.value].questions);
                                            selectPlacementRiskAssessmentOrDbsCheck({e: e, setLoading: setDbsCheckLoading, type: "dbsCheck", placement: placement});
                                            selectPlacementRiskAssessmentOrDbsCheck({e: e, setLoading: setRiskAssessmentLoading, type: "riskAssessment", placement: placement});
                                            setPlacementId(e.target.value);
                                            setMapConsent(placement.existingPlacements?.[e.target.value].mapConsent);
                                        }}>
                                            {Object.entries(placement.existingPlacements).map(([id, p]) =>
                                                <MenuItem value={id}>{p.jobTitle}</MenuItem>
                                            )}
                                        </Dropdown>
                                    </Grid></>}
                                {placement && placement.requireRA && completedSections && <Grid item xs={12}>
                                    {Object.keys(completedSections).includes("riskAssessment") && onCompleteSection && <UploadProviderRiskAssessment loading={riskAssessmentLoading} {...{pId, placement}} completed={completedSections.riskAssessment} disabled={!completedSections.details} placementListingId={placement.placementId} providerContactId={placement?.providerContactId} onComplete={() => {
                                        onCompleteSection("riskAssessment");
                                    }}/>}
                                </Grid>}
                                {placement && placement.requireDBS && completedSections && <Grid item xs={12}>
                                    {Object.keys(completedSections).includes("dbsCheck") && onCompleteSection && <UploadProviderDbsCheck {...{pId, placement}} completed={completedSections.dbsCheck} disabled={!completedSections.details} placementListingId={placement.placementId} providerContactId={placement?.providerContactId} loading={dbsCheckLoading} onComplete={() => {
                                        onCompleteSection("dbsCheck");
                                    }}/>}
                                </Grid>}
                                <Grid item xs={12}>
                                    <InputGroup label={"Job title"} name={"title"} value={questions?.title} required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup value={questions?.description} name={"description"} label={"General description"} multiline minRows={3} required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.firstDayContact} name={"firstDayContact"} label={"First day contact"} placeholder={"E.g. John Smith"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.firstAider} name={"firstAider"} label={"First aider"} placeholder={"E.g. John Smith"}/>
                                </Grid>
                                <Grid item xs={12} md={dressCodeOther ? 6 : 12}>
                                    <Dropdown label={"Dress code"} id={"dressCode"} required name={"dressCode"} value={questions?.dressCode} onChange={(e) => setDressCodeOther(e.target.value === "other")}>
                                        <MenuItem value={"businessFormal"}>Business Formal</MenuItem>
                                        <MenuItem value={"businessProfessional"}>Business Professional</MenuItem>
                                        <MenuItem value={"businessCasual"}>Business Casual</MenuItem>
                                        <MenuItem value={"casual"}>Casual</MenuItem>
                                        <MenuItem value={"other"}>Other</MenuItem>
                                    </Dropdown>
                                </Grid>
                                {dressCodeOther && <Grid item xs={12} md={6}>
                                    <InputGroup value={questions?.dressCodeText} name={"dressCodeText"} label={"Dress code"} placeholder={"Please specify"} required/>
                                </Grid>}
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.hours} name={"hours"} label={"Working days and hours"} placeholder={"Mon-Fri 9-5"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            </FadeInBox>
            <FadeInBox card cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={() => setPage("questionsOne")} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Placement details</Typography>]} visible={visible && page === "questionsTwo"}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form functionType='sync' onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage(placementId ? (Object.keys(placement?.instituteActivities || {}).length ? "activities" : "submitPlacement") : uid ? "consentPage" : "advertisePlacement");
                }} submitText={"Next"}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"carParking"}
                                            name={"carParking"}
                                            defaultChecked={questions?.carParking}/>}
                                        label={"Car parking"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"bikeStorage"}
                                            name={"bikeStorage"}
                                            defaultChecked={questions?.bikeStorage}/>}
                                        label={"Bike storage"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"wheelchairFriendly"}
                                            name={"wheelchairFriendly"}
                                            defaultChecked={questions?.wheelchairFriendly}/>}
                                        label={"Wheelchair friendly"}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={<Checkbox key={"kitchen"}
                                            name={"kitchen"}
                                            defaultChecked={questions?.kitchen}/>}
                                        label={"Kitchen"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.lunchArrangements} name={"lunchArrangements"} label={"Lunch arrangements"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker required items={questions?.responsibilities} name={"responsibilities"} label={"Responsibilities"} subtitle='What will the student be responsible for?'>
                                        <InputGroup placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.equipment} name={"equipment"} label={"Required equipment"} subtitle='What should the student bring?'>
                                        <InputGroup placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.experiencesGiven} name={"experiencesGiven"} label={"Experience given"} subtitle='What experiences can the student expect to gain?' required>
                                        <InputGroup placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListMaker items={questions?.experienceDesired} name={"experienceDesired"} label={"Experience desired"} subtitle='What skills will the student need to be proficient in?'>
                                        <InputGroup placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup multiline rows={3} value={questions?.otherAdvice} name={"otherAdvice"} label={"Other relevant information"} placeholder={"E.g. Preparation, directions"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            </FadeInBox>
            <FadeInBox card visible={visible && page === "submitPlacement"} cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={() => setPage("questionsTwo")} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Submit Review</Typography>]}>
                <Stack>
                    <Typography variant={"h6"}>Click the button below to submit your placement review to {placement?.instituteName}</Typography>
                    <LoadingButton onClick={() => onSubmitQuestions()} variant='contained' text="Submit"/>
                </Stack>
            </FadeInBox>
            <FadeInBox card cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={() => setPage("questionsTwo")} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Placement details</Typography>]} visible={visible && page === "advertisePlacement"}>
                <Typography variant='subtitle2'>This information will be available to the student, to help them prepare, and provide you with a student who is ready to learn. Please be as detailed as possible.</Typography>
                <Form onSubmit={(e) => {
                    setQuestions((q) => ({...q, ...e} as PlacementQuestions)); setPage(Object.keys(placement?.instituteActivities || {}).length ? "activities" : "submitPlacement");
                }} submitText={"Next"} functionType="sync">
                    <Stack spacing={2} alignItems={"center"}>
                        <Grid xs={12} item sx={{width: "100%"}}>
                            <Grid container sx={{textAlign: "left"}}>
                                <Grid item xs={12}>
                                    <Dropdown multiple label={"Student level"} required name={"studentLevel"} value={questions?.studentLevel}>
                                        <MenuItem value={"gcse"}>GCSE (14-16)</MenuItem>
                                        <MenuItem value={"college"}>Sixth form / college (16-18)</MenuItem>
                                        <MenuItem value={"undergraduate"}>Undergraduate</MenuItem>
                                        <MenuItem value={"postgraduate"}>Postgraduate</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropdown label={"Working location"} required name={"workingLocation"} value={questions?.workingLocation}>
                                        <MenuItem value={"inPerson"}>In-person</MenuItem>
                                        <MenuItem value={"hybrid"}>Hybrid</MenuItem>
                                        <MenuItem value={"remote"}>Remote</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputGroup label={"Desired placement length"} min={1} name={"desiredLength"} value={questions?.desiredLength} type={"number"} required/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Dropdown label={"Units"} required name={"desiredLengthUnits"} value={questions?.desiredLengthUnits || "weeks"}>
                                        <MenuItem value={"days"}>Days</MenuItem>
                                        <MenuItem value={"weeks"}>Weeks</MenuItem>
                                        <MenuItem value={"months"}>Months</MenuItem>
                                    </Dropdown>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputGroup required value={questions?.maximumStudents} min={1} type={"number"} name={"maximumStudents"} label={"Maximum number of students"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Typography>By submitting this placement, you are agreeing to us advertising your placement {instituteName ? `to students at ${instituteName}` : "on Placementt"}. Students may contact you regarding the opportunities you post.</Typography>
                </Form>
            </FadeInBox>
            <FadeInBox card visible={visible && page === "consentPage"}>
                <Stack spacing={2} alignItems={"center"}>
                    {placement && <RequestMapConsent placement={placement} onSubmit={async (mc) => {
                        console.log("EVENETS", placement.eventsConsent);
                        if (placement.eventsConsent !== undefined && placement.eventsConsent !== null) {
                            await onSubmitQuestions({mapConsent: mc});
                            return;
                        }
                        setMapConsent(mc);
                        setPage("eventsConsentPage");
                        return;
                    }}/>}
                </Stack>
                <IconButtonPop onClick={() => setPage("questionsTwo")} responsive={false} title={"Back"} sx={{position: "absolute", top: "14px", left: "20px"}}>
                    <ArrowBack/>
                </IconButtonPop>
            </FadeInBox>
            <FadeInBox card visible={visible && page === "eventsConsentPage"} cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={() => setPage("consentPage")} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Our Events</Typography>]}>
                <Stack pt={4} alignItems={"center"} textAlign={"center"}>
                    {placement?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={placement.primaryImage}/>}
                    <Typography variant='h5' color={placement?.primaryColor || PRIMARY_COLOUR}>{placement?.instituteName}</Typography>
                    <Typography>We run various careers events for our students throughout the year, and employer engagement is a vital part of our careers programme.</Typography>
                    <Divider/>
                    <Typography variant="h6" color={placement?.primaryColor || PRIMARY_COLOUR}>Would you be happy for us to contact you about upcoming careers events?</Typography>
                    <Typography>By joining our contact list, you'll receive updates on relevant opportunities for employer engagement.</Typography>
                    <Divider/>
                    <Typography variant="caption">Your details will be stored securely in {placement?.instituteName}'s private employer database. We may reach out to you about upcoming events, and you can request to be removed from our list at any time by contacting the school.</Typography>
                    <LoadingButton>
                        <Button onClick={async () => await onSubmitQuestions({eventsConsent: false})}>Skip</Button>
                        <Button variant="contained" onClick={async () => {
                            if (Object.keys(placement?.instituteActivities || {}).length) {
                                setEventsConsent(true);
                                setPage("activities");
                            } else {
                                await onSubmitQuestions({eventsConsent: true});
                            }
                        }}>Join Contact List</Button>
                    </LoadingButton>
                </Stack>
            </FadeInBox>
            <FadeInBox card width={"1000px"} visible={visible && page === "activities"} cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={() => setPage("eventsConsentPage")} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} m={"auto"} variant={"h4"}>Our Activities</Typography>]} >
                <Stack spacing={2} alignItems={"center"}>
                    <Typography>{placement?.consentedActivities?.length ? "Review the activities you have already consented to, and select others you may be interested in." : (instituteName || "Our school ")+" hosts various other activities to enhance their career provision. Please select any other activities you are interested in being contacted about."}</Typography>
                    <Typography textAlign={"center"} fontWeight={"bold"} fontSize={"1.2em"} color={placement?.primaryColor}>There is no committment now, we will only contact you when we organise an event.</Typography>
                    <Grid container>
                        {Object.entries(placement?.instituteActivities || {}).map(([key, activity]) =>
                            <Grid item xs={12} sm={6} key={key}>
                                <ListItemButton onClick={() => setSelectedActivities((a) => {
                                    if (a.includes(key)) {
                                        return [...a].filter((k) => k !== key);
                                    } else {
                                        return [...a, key];
                                    }
                                })} sx={{borderRadius: 2, borderColor: activity.color, borderWidth: 2, borderStyle: "solid", mb: 1}}>
                                    <ListItemIcon><Checkbox checked={selectedActivities.includes(key)} style={{color: activity?.color}}/></ListItemIcon>
                                    <ListItemText
                                        primary={activity.title}
                                        primaryTypographyProps={{color: activity.color, fontWeight: "bold"}}
                                        secondary={<Stack spacing={0}>
                                            <Typography sx={selectedActivities.includes(key) ? {} : {textOverflow: "ellipsis", lineClamp: 2, WebkitBoxOrient: "vertical", WebkitLineClamp: 3, display: "-webkit-box", maxHeight: "100px", overflow: "hidden"}}>{activity.description}</Typography>
                                        </Stack>}
                                    />
                                </ListItemButton>
                            </Grid>
                        )}
                    </Grid>
                    <LoadingButton variant="contained" text="Submit" onClick={() => onSubmitQuestions()}/>
                </Stack>
            </FadeInBox>
        </>
    );
}

function RequestMapConsent({placement, onSubmit}:{placement: PlacementReviewDetails, onSubmit: (mapConsent: boolean) => Promise<void>}) {
    return (
        <Stack pt={4} alignItems={"center"} textAlign={"center"}>
            {placement?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={placement.primaryImage}/>}
            <Typography variant='h5' color={placement.primaryColor || PRIMARY_COLOUR}>{placement.instituteName}</Typography>
            <Typography>Thank you for agreeing to support a student this year with their work experience.</Typography>
            <Divider/>
            <Typography variant="h6" color={placement.primaryColor || PRIMARY_COLOUR}>Would you like to provide opportunity for future students to contact you regarding work experience?</Typography>
            <Typography>Sharing this work experience opportunity would be invaluable to our student's career development.</Typography>
            <Divider/>
            <Typography variant="caption">Consent to us listing your placement on {placement.instituteName}'s private employer database. This requires no action from you, though you can take control of the listing at any time.</Typography>
            <LoadingButton>
                <Button onClick={async () => await onSubmit(false)}>No, continue</Button>
                <Button onClick={async () => await onSubmit(true)} variant='contained'>Share placement</Button>
            </LoadingButton>
        </Stack>
    );
}
