import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Grid, Stack, Switch, Typography} from "@mui/material";
import {PRIMARY_COLOUR, snakeCase} from "placementt-core";
import React, {ReactNode, useEffect, useState} from "react";
import {ToggleFormItem} from "../../Util/styledComponents";
import IconButtonPop from "../IconButtonPop";

type Params = {
    name?: string,
    formData: {[key:string]: unknown},
    children: ReactNode,
    disabled: boolean,
    label: string,
}

export default function OptionalSection({name="", formData, children, disabled, label}:Params) {
    const [dropdownActive, setDropdownActive] = useState(false);
    const [childCheckboxValues, setChildCheckboxValues] = useState<{[key:string]: unknown}>({});

    const populateInitialChildCheckboxValues = (c: ReactNode, checkboxValues:{[key:string]: unknown}={}) => {
        // console.log("LOADING Children", c);
        React.Children.toArray(c).map((child) => {
            console.log(child);
            if (!React.isValidElement(child) || typeof child.type === "string") {
                // console.log("Return");
                return false;
            }

            // console.log(child.type.name);
            if ((child.type as {componentName?: string}).componentName === "ControlledSwitch") {
                checkboxValues[child.props.name] = child.props.defaultChecked || false;
            }

            if (child.props.children) {
                return populateInitialChildCheckboxValues(child.props.children, checkboxValues);
            } else {
                return checkboxValues;
            }
        });
        return checkboxValues;
    };

    useEffect(() => {
        // console.log("children", children);
        const checkboxValues = populateInitialChildCheckboxValues(children);
        // console.log("CBV", checkboxValues);
        setChildCheckboxValues(checkboxValues);
        setDropdownActive(masterActive(checkboxValues));
    }, []);

    const injectPropsToAllChildren = (children: ReactNode):ReactNode => {
        return React.Children.toArray(children).map((child) => {
            if (!React.isValidElement(child)) {
                return child;
            }

            const childzFurtherChildren = child.props.children ?
                injectPropsToAllChildren(child.props.children) :
                undefined;

            const props = {defaultChecked: formData[child.props.name], checked: childCheckboxValues[child.props.name], onCheck: (name: string, value: unknown) => updateCheckboxValues(name, value)};

            return childzFurtherChildren ?
                React.cloneElement(child, props, childzFurtherChildren) :
                React.cloneElement(child, props);
        });
    };

    const updateCheckboxValues = (name: string, value: unknown) => {
        setChildCheckboxValues((prev) => ({...prev, [name]: value}));
    };

    const toggleMaster = () => {
        const active = masterActive();
        if (!active) {
            setDropdownActive(true);
        }
        setChildCheckboxValues((prev) => (Object.fromEntries(Object.keys(prev).map((key) => [key, !active]))));
    };

    const masterActive = (checkboxValues = childCheckboxValues) => {
        return Object.values(checkboxValues).includes(true);
    };

    return (
        <Grid container item xs={12}>
            <Grid item xs={12} justifyContent='space-between' display={"flex"}>
                <ToggleFormItem style={{flex: 1}}>
                    <Typography>{label}</Typography>
                    <Switch id={snakeCase(name)} name={snakeCase(name)} defaultChecked={masterActive()} onClick={toggleMaster} checked={masterActive()} disabled={disabled}/>
                </ToggleFormItem>
                <IconButtonPop responsive={false} title={dropdownActive ? "Collapse" : "Expand"} onClick={() => setDropdownActive((a) => !a)}>
                    {dropdownActive ? <ArrowDropUp/> : <ArrowDropDown/>}
                </IconButtonPop>
            </Grid>
            <Grid container item xs={12} sx={{paddingLeft: "10px", transition: "all 0.7s ease-in-out"}} maxHeight={dropdownActive ? "500px" : 0} overflow={"hidden"}>
                <Stack ml={5} width={"100%"} mt={2} mb={2} borderLeft={`2px solid ${PRIMARY_COLOUR}`}>
                    {injectPropsToAllChildren(children)}
                </Stack>
            </Grid>
        </Grid>
    );
}
