import {Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {QueryConstraint, where} from "firebase/firestore";
import mapboxGl, {Marker} from "mapbox-gl";
import {ERRORTEXTCOLOR, PRIMARY_COLOUR, PlacementListing, UserData, average, decodeGeoHash, executeCallable} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {ReactNode, createRef, useContext, useEffect, useRef, useState} from "react";
import geoHash from "ngeohash";
import {ArrowLeft, ArrowForward, StarRounded} from "@mui/icons-material";
import {UserContext} from "../../App";
import IconButtonPop from "../../Components/IconButtonPop";
import styled from "styled-components";
import {Popup} from "../../Components/Popup";
import {ProviderAddress, ProviderContactDetails, ProviderGuidance} from "./ViewPlacement";
import {LoadingButton} from "../../Components/LoadingButton";
import Alert from "../../Components/Alert";
import {useNavigate} from "react-router-dom";


const MapContainer = styled(Box)`
    background-color: white;
    width: 100%;
    height: calc(100% + 50px);
    position: absolute;
    top: ${(params:{fullscreen: boolean}) => params.fullscreen ? "50px" : 0};
    left: 0;
`;

const Drawer = styled(Card)<{open: boolean}>`
    position: fixed !important;
    height: 100%;
    width: 500px;
    max-width: calc(95vw - 35px);
    z-index: 1200;
    right: 0;
    transform: ${(params) => params.open ? "translate(0, 0)" : "translate(100%, 0)"};
    overflow: visible;
    transition: all 250ms ease-in-out;
`;

mapboxGl.accessToken = "pk.eyJ1IjoidGhvbWFzYmFydG9uMTAwIiwiYSI6ImNrd3dkNjE3MzAyZzcydnExdTZiZ256MXYifQ.B5V75w78TKOYqs8NjeHqUg";

type Params = {
    type?: "internal"|"external",
    title: string,
    secondary?: React.ReactNode,
    center?: string|[number, number],
    fullscreen?: boolean,
    cohort?: string,
    noDrawer?: boolean,
    initialZoom?: number,
    centerIcon?: string,
};

export default function PlacementMap({type, cohort, title, secondary, initialZoom, center, centerIcon, fullscreen=true, noDrawer}:Params) {
    const [drawerOpen, setDrawerOpen] = useState(true);


    const mapContainer = useRef<any>(null);
    const map = useRef<mapboxgl.Map | null>(null);

    const [lng, setLng] = useState<number>(center ? typeof center === "string" ? decodeGeoHash(center)?.longitude as number : center[1] : 1);
    const [lat, setLat] = useState<number>(center ? typeof center === "string" ? decodeGeoHash(center)?.latitude as number : center[0] : 54.7);
    const [zoom, setZoom] = useState<number>(initialZoom || (center ? 8 : 5));
    const [placements, setPlacements] = useState<{[key: string]: PlacementListing}>();
    const [placementMarkers, _setPlacementMarkers] = useState<Marker[]>([]);
    const [renderedPlacements, setRenderedPlacements] = useState<ReactNode>();
    const [selectedListing, setSelectedListing] = useState<{id: string, listing: PlacementListing}|undefined>();
    const [reserveListingPopup, setReserveListingPopup] = useState(false);

    const [createPlacementPopup, setCreatePlacementPopup] = useState<{id: string, listing: PlacementListing}|undefined>();

    // const [selectedPlacement, setSelectedPlacement] = useState<PlacementListing>();
    // const [viewPlacementPopup, setViewPlacementPopup] = useState<PlacementListing|false>(false);

    // useEffect(() => {
    //     if (viewPlacementPopup) {
    //         setSelectedPlacement(viewPlacementPopup);
    //         return;
    //     }
    // }, [viewPlacementPopup]);

    const firebaseQuery = new FirebaseQuery();
    const scrollRefs = useRef<{[key:string]: React.MutableRefObject<any>}>({});

    const placementMarkersRef = useRef(placementMarkers);

    const setPlacementMarkers = (data:Marker[]) => {
        placementMarkersRef.current = data;
        _setPlacementMarkers(data);
    };

    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        if (map.current) return; // initialize map only once


        map.current = new mapboxGl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            zoom: zoom,
        });

        getMapPlacements(map.current.getBounds(), map.current.getZoom());

        map.current.on("move", () => {
            if (!map.current) return;

            setLng(parseFloat(map.current.getCenter().lng.toFixed(4)));
            setLat(parseFloat(map.current.getCenter().lat.toFixed(4)));
            setZoom(parseFloat(map.current.getZoom().toFixed(2)));
        });

        map.current.on("moveend", () => {
            if (!map.current) return;
            getMapPlacements(map.current.getBounds(), map.current.getZoom());
        });

        if (center) {
            const el = document.createElement("div");
            el.className = "marker";
            el.innerHTML = `<span><b>${centerIcon ? `<i class="fa-solid fa-${centerIcon}"></i>` : "•"}</b></span>`;
            new Marker(el)
                .setLngLat(typeof center === "string" ? [decodeGeoHash(center)?.longitude as number, decodeGeoHash(center)?.latitude as number] : [center[1], center[0]])
                .addTo(map.current);
        }

        return () => {
            if (!map.current) return;
            map.current.off("moveend", () => {
                if (!map.current) return;
                getMapPlacements(map.current.getBounds(), map.current.getZoom());
            });
        };
    }, []);

    const getMapPlacements = async (bounds:mapboxGl.LngLatBounds, zoom:number) => {
        // let placementResults:{[key:string]: StudentPlacementData} = {};
        // let placementCounts:{[key:string]: {count:number, startCode:string, endCode: string}} = {};

        const geoHashLength = Math.ceil(zoom * (9/22));
        if (geoHashLength <= 1) return;

        const latUpper = bounds.getNorthEast().lat;
        const latLower = bounds.getSouthWest().lat;
        const lngUpper = bounds.getNorthEast().lng;
        const lngLower = bounds.getSouthWest().lng;

        let latCurrent = latLower;
        let lngCurrent = lngLower;

        const codes:string[] = [];

        let geoHashCode = geoHash.encode(latLower, lngLower, geoHashLength);
        let geoHashBox = geoHash.decode_bbox(geoHashCode);

        codes.push(geoHashCode);

        while (latCurrent < latUpper) {
            const leftmostRowHashCode = geoHashCode;

            lngCurrent = geoHashBox[3];

            while (lngCurrent < lngUpper) {
                geoHashCode = geoHash.neighbor(geoHashCode, [0, 1]);
                geoHashBox = geoHash.decode_bbox(geoHashCode);
                lngCurrent = geoHashBox[3];
                codes.push(geoHashCode);
            }

            latCurrent = geoHashBox[2];

            if (latCurrent < latUpper) {
                geoHashCode = geoHash.neighbor(leftmostRowHashCode, [1, 0]);
                geoHashBox = geoHash.decode_bbox(geoHashCode);
                codes.push(geoHashCode);
            }
        }

        if (type !== "internal") return;

        const savedPlacements = await firebaseQuery.getDocsWhere(["placementListings"], [where(`savedBy.${user.oId}.exists`, "==", true), cohort ? where(`savedBy.${user.oId}.cohorts.${cohort}.exists`, "==", true) : undefined, cohort ? where(`savedBy.${user.oId}.cohorts.${cohort}.listed`, "==", true) : undefined].filter((e) => e) as QueryConstraint[]) as {[key:string]: PlacementListing};


        const placementListings = Object.fromEntries((await Promise.all(Object.entries(savedPlacements).map(async ([k, placement]) => {
            if (placement.concurrentPlacements !== undefined) {
                const concurrentPlacements = await firebaseQuery.getCount(["placements"], [where("placementId", "==", k), where("oId", "==", user.oId), where("inProgress", "==", true)]);

                console.log("max placements", placement.concurrentPlacements, "limit reached", placement.concurrentPlacements && (concurrentPlacements >= placement.concurrentPlacements));

                if (concurrentPlacements >= placement.concurrentPlacements) return false;
            }
            if (!placement.providerId) return false;


            return [k, placement];
        }))).filter((i) => i) as [string, PlacementListing][]);


        const placementCounts = [];
        let placementResults:{[key:string]: PlacementListing} = {};

        for (const code of codes) {
            const placementsInArea = Object.fromEntries(Object.entries(placementListings).filter(([, p]) => {
                return p.geoHash?.startsWith(code);
            }));
            const count = Object.keys(placementsInArea).length;

            if (!count) continue;

            if (count > 1) {
                const coords = Object.entries(placementsInArea).reduce((acc, [, placement]) => {
                    const geoHashCode = geoHash.decode(placement.geoHash as string);
                    acc.lat = [...acc.lat, geoHashCode.latitude];
                    acc.lng = [...acc.lng, geoHashCode.longitude];
                    return acc;
                }, {lat: [], lng: []} as {lat: number[], lng: number[]});
                placementCounts.push({lat: average(coords.lat), lng: average(coords.lng), count: count});
                continue;
            }

            placementResults = {...placementResults, ...placementsInArea};
        }

        const currentMarkers:Marker[] = [];

        placementMarkersRef.current.forEach((marker) => marker.remove());

        Object.entries(placementResults).forEach(([id, placement]) => {
            if (!map.current) return;

            const geoHashCode = geoHash.decode(placement.geoHash as string);


            const el = document.createElement("div");
            el.className = "marker";
            el.innerHTML = "<span><b>•</b></span>";
            el.addEventListener("click", () => {
                setSelectedListing({id: id, listing: placement});
            });
            // const popup = new Popup({offset: 25}).setHTML(`<div style={"borderRadius: 10px"}>
            //     {placement.name}
            // </div>`);

            const marker = new Marker(el)
                .setLngLat([geoHashCode.longitude, geoHashCode.latitude])
                // .setPopup(popup)
                .addTo(map.current);

            currentMarkers.push(marker);
        });

        Object.entries(placementCounts).forEach(([, {count, lat, lng}]) => {
            if (!map.current) return;

            const el = document.createElement("div");
            el.className = "marker";
            el.innerHTML = "<span><b>" + (count) + "</b></span>";


            const marker = new Marker(el)
                .setLngLat([lng, lat])
                .addTo(map.current);


            currentMarkers.push(marker);
        });
        setPlacements(placementListings);
        setPlacementMarkers(currentMarkers);
    };

    useEffect(() => {
        if (!selectedListing) return;
        scrollRefs.current[selectedListing.id].current.scrollIntoView({behavior: "smooth"});
    }, [selectedListing]);

    useEffect(() => {
        const renderPlacements = async () => {
            if (!placements) return;

            if (!Object.keys(placements).length) {
                setRenderedPlacements(<Typography variant="h6" sx={{opacity: 0.5}}>No placements on this date.</Typography>);
                return;
            }

            scrollRefs.current = Object.fromEntries(Object.keys(placements).map(
                (k, i) => [k, scrollRefs.current[i] ?? createRef()]
            ));

            setRenderedPlacements(Object.entries(placements).map(([key, placement]) => {
                console.log(placement);

                return (<ListItemButton onClick={() => {
                    setSelectedListing({id: key, listing: placement});
                    // setViewPlacementPopup(proposePlacementData as PlacementListing);
                }} key={key} divider ref={scrollRefs.current[key]}>
                    {(user.reservedListings?.filter((l) => l.listingId === key) || []).length > 0 && <ListItemIcon><IconButtonPop title="Reserved" responsive={false}><StarRounded color="primary"/></IconButtonPop></ListItemIcon>}
                    <ListItemText sx={{mr: "130px"}} primary={<Typography><Typography fontWeight={"bold"} display={"inline"}>{placement.name}</Typography> - {placement.title}</Typography>} secondary={<Typography color={"grey"}>{placement["address-line1"]}, {placement["address-line2"] && `${placement["address-line2"]}, `}{placement.postal_code}</Typography>}/>
                    <ListItemSecondaryAction>
                        <Stack spacing={0} alignItems={"flex-end"} justifyContent={"flex-end"}>
                            {/* <Chip color={placement.status as any === "Accepted" ? "primary" : undefined} label={placement.status as any === "Accepted" ? "Verified by provider" : "Not verified"}/> */}
                            {user.userType === "Students" && <IconButtonPop sx={{alignSelf: "flex-end !important"}} responsive={false} title="View Listing" onClick={() => setSelectedListing({id: key, listing: placement})}><ArrowForward color="primary"/></IconButtonPop>}
                        </Stack>
                    </ListItemSecondaryAction>
                </ListItemButton>);
            }));
        };

        renderPlacements();
    }, [placements, selectedListing]);

    const reserveListing = async (id?: string) => {
        if (!id) return;
        await executeCallable("placementListing-reserve", {id: id});
    };

    const removeReservation = async (id?: string) => {
        if (!id) return;
        await executeCallable("placementListing-unreserve", {id: id});
    };

    useEffect(() => {
        console.log("CPP", createPlacementPopup);
    }, [createPlacementPopup]);

    return (
        <Box position={"relative"} overflow={"hidden"} flex={1} height={"100%"}>
            {noDrawer || <Drawer open={drawerOpen}>
                <Box sx={{position: "absolute", left: "-35px", top: fullscreen ? "90px" : "30px", background: "white", borderRadius: "15px 0 0 15px", clipPath: "inset(-5px 5px -10px -10px)"}} boxShadow={3}>
                    <IconButtonPop responsive={false} title="Open" onClick={() => setDrawerOpen((o) => !o)}>
                        <ArrowLeft sx={{transition: "all 250ms ease-in-out", transform: drawerOpen ? "rotate(180deg)" : "none"}}/>
                    </IconButtonPop>
                </Box>
                <CardContent>
                    <Stack height={"100%"}>
                        <Typography variant="h4">{title}</Typography>
                        {secondary}
                        <List sx={{overflowY: "auto"}}>
                            {renderedPlacements}
                        </List>
                    </Stack>
                </CardContent>
            </Drawer>}
            <MapContainer fullscreen={fullscreen} ref={mapContainer}/>
            {user.userType === "Students" && <Popup maxWidth={"md"} fullWidth title={selectedListing?.listing.name} open={Boolean(selectedListing)} onClose={() => setSelectedListing(undefined)} actions={selectedListing && (user.reservedListings?.filter((l) => l.listingId === selectedListing.id) || []).length > 0 &&
                <Stack direction={"row"}>
                    <Alert severity="primary" action={<LoadingButton onClick={async () => await removeReservation(selectedListing.id)} text="Remove"/>}>You have reserved this placement</Alert>
                    <Button onClick={() => setCreatePlacementPopup(selectedListing)}>Create Placement</Button>
                </Stack>}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {selectedListing && (user.reservedListings?.filter((l) => l.listingId === selectedListing.id) || []).length > 0 && <Box borderRadius={2} p={2} pt={0} border={`2px solid ${PRIMARY_COLOUR}`}><ProviderContactDetails details={selectedListing.listing}/></Box>}
                        {selectedListing && <ProviderGuidance guidance={selectedListing?.listing.questions} listing/>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {selectedListing && <ProviderAddress placement={selectedListing?.listing}/>}
                        {(user.reservedListings?.filter((l) => l.listingId === selectedListing?.id).length || 0) === 0 && !selectedListing?.listing.savedBy?.[user.oId].cohorts?.[user.cohort as string]?.studentPlacements?.includes(user.id) &&
                        <Stack borderRadius={2} border={`2px solid ${PRIMARY_COLOUR}`} p={2} textAlign={"center"} m={2} alignItems={"center"}>
                            <Typography variant="h5">Reserve Placement</Typography>
                            <Typography>Click "Reserve" to view contact details <u><strong style={{color: PRIMARY_COLOUR}}>only</strong></u> if you intend to contact the employer, or have already organised a placement.</Typography>
                            <Button variant="contained" onClick={() => setReserveListingPopup(true)}>Reserve and View Contact</Button>
                            <Typography variant="caption">Already organised and confirmed a placement here? <Button variant="text" onClick={() => setCreatePlacementPopup(selectedListing)}>Click here</Button></Typography>
                        </Stack>}
                    </Grid>
                </Grid>
            </Popup>}
            <Popup key={"reservePopup"} open={reserveListingPopup} onClose={() => setReserveListingPopup(false)} title={"Reserve Listing"}>
                <Stack borderRadius={2} p={2} textAlign={"center"} ml={2} mr={2} alignItems={"center"}>
                    <Typography>Click "Reserve" to view contact details <u><strong style={{color: PRIMARY_COLOUR}}>only</strong></u> if you intend to contact the employer, or have already organised a placement.</Typography>
                    <Typography>Listings can only be reserved for 3 days. Once this expires, you can no longer view contact details and other students can view details.</Typography>
                    <LoadingButton variant="contained" onClick={async () => await reserveListing(selectedListing?.id)} text="Reserve and View Contact"/>
                    <Typography variant="caption">Already organised and confirmed a placement here? <Button variant="text" onClick={() => setCreatePlacementPopup(selectedListing)}>Click here</Button></Typography>
                    {selectedListing && (user.reservedListings?.filter((l) => l.listingId === selectedListing.id) || []).length > 0 && <Box width={"100%"} borderRadius={2} p={2} pt={0} border={`2px solid ${PRIMARY_COLOUR}`}><ProviderContactDetails details={selectedListing.listing}/></Box>}
                </Stack>
            </Popup>
            <CreatePlacementFromListingPopup id={createPlacementPopup?.id} listing={createPlacementPopup?.listing} open={Boolean(createPlacementPopup)} onClose={() => setCreatePlacementPopup(undefined)}/>
        </Box>
    );
}

export function CreatePlacementFromListingPopup({id, listing, open, onClose}:{id?: string, listing?: PlacementListing, open: boolean, onClose: () => void}) {
    const proposePlacementData:{[key:string]: unknown} = {...listing, id: id};
    delete proposePlacementData.sector;
    delete proposePlacementData.subsector;
    delete proposePlacementData.savedBy;
    delete proposePlacementData.created;
    delete proposePlacementData.oId;

    const [understood, setUnderstood] = useState(false);
    const user = useContext(UserContext);

    const navigate = useNavigate();

    return (
        <Popup {...{open, onClose}} title={"Create Placement"}>
            <Stack borderRadius={2} p={2} textAlign={"center"} ml={2} mr={2} alignItems={"center"}>
                <Typography>Only create a placement if you have already confirmed with the employer.</Typography>
                <Typography color={ERRORTEXTCOLOR}>If you have not contacted the employer and received confirmation, adding your placement here is not a confirmation and <strong><u>your placement will not go ahead</u></strong>.</Typography>
                <FormControlLabel label={"I confirm I have contacted this business directly, and received confirmation of my place on this placement on the dates specified."} control={<Checkbox onChange={(e) => setUnderstood(e.target.checked)}/>}/>
                <Button disabled={!understood} variant="contained" onClick={() => navigate(`/${user.product}/create`, {state: {placement: {...proposePlacementData, placementId: id, id: undefined}}})}>Create placement</Button>
            </Stack>
        </Popup>
    );
}
