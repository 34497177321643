import {Link, Stack} from "@mui/material";
import {getRandomNumber, isJson} from "placementt-core";
import React, {useState, ReactNode, forwardRef, useImperativeHandle, Children, useEffect, useRef} from "react";
import {LoadingButton, LoadingButtonParams, ResponseObject} from "./LoadingButton";


interface Params {
    onSubmit?: (e:{[key:string]:unknown}) => Promise<any> | void,
    errorStyle?: object,
    children?: ReactNode,
    className?: string,
    sx?: object,
    formRef?: React.RefObject<HTMLFormElement|null>,
    showError?: boolean,
    onError?: (e: string) => void,
    onBackPress?: () => void,
    noValidate?: boolean,
    submitText?: string,
    button?: boolean,
    disabled?: boolean,
    functionType?: "async"|"sync",
    successText?: string,
    successDismissible?: boolean,
    spacing?: number,
    readOnly?: boolean
}

const FormButton = (props: LoadingButtonParams) => <LoadingButton {...props}/>;
FormButton.componentName = "FormButton";
export {FormButton};

const FormButtonGroup = (props: LoadingButtonParams) => {
    return <LoadingButton {...props}>{props.children}</LoadingButton>;
};
FormButtonGroup.componentName = "FormButtonGroup";
export {FormButtonGroup};

const Form = forwardRef(({onSubmit, functionType="async", readOnly, successText, successDismissible, children, className, sx, formRef, onError, noValidate, submitText, onBackPress, button=true, disabled=false, spacing=2}:Params, ref) => {
    const [buttonState, setButtonState] = useState<"normal"|"loading"|"success"|ResponseObject>("normal");
    const [key] = useState(getRandomNumber(0, 1000000000000));

    const [buttonFunctions, setButtonFunctions] = useState<{[key: string]:(e: any) => Promise<any>}>();
    const buttonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => ({
        resetButton() {
            setButtonState("normal");
        },
        submitForm() {
            buttonRef.current?.click();
        },
    }));

    useEffect(() => {
        const getButtonFunctions = (children:ReactNode):[string, (e: any) => Promise<any>][] => {
            const res = Children.toArray(children).filter((child) => child !== null).map((child, index) => {
                if (!React.isValidElement(child)) {
                    return [];
                }

                const componentName = (child.type as {componentName?: string}).componentName;
                const funcs: [string, (e: any) => Promise<any>][] = [];


                if (componentName === "FormButton") {
                    funcs.push([`formButton-${index}-${key}`, child.props.onClick]);
                }

                return child.props.children ?
                    [...funcs, ...getButtonFunctions(child.props.children)] :
                    funcs;
            });
            return res?.reduce((acc, item) => {
                acc.push(...item);
                return acc;
            }, [] as [string, (e: any) => Promise<any>][]) || [];
        };

        setButtonFunctions(Object.fromEntries(getButtonFunctions(children)));
    }, [children]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setButtonState("loading");

        const formData: {[k: string]: any} = {};


        for (let i = 0; i < e.target.length; i++) {
            const element = e.target[i];
            if (!noValidate && (!element.validity.valid || (element.getAttribute("require") && element.value === ""))) {
                setButtonState(new Error("Please fill in the required fields."));
                onError && onError("Please fill in the required fields.");
                return;
            } else {
                // console.log(element.name, element.value);
                if (element.name === "") {
                    continue;
                }

                if (element.type === "checkbox") {
                    formData[element.name] = element.checked;
                    continue;
                }

                if (isJson(element.value)) {
                    formData[element.name] = JSON.parse(element.value);
                    continue;
                }

                if (element.type === "file") {
                    formData[element.name] = element.files;
                    continue;
                }

                formData[element.name] = element.value.trim();
            }
        }

        const submitterName = e.nativeEvent?.submitter?.id;

        if (buttonFunctions?.[submitterName] && submitterName) {
            buttonFunctions?.[submitterName](formData);
        }

        if (functionType === "sync") {
            onSubmit && onSubmit(formData);
            setButtonState("normal");
            return;
        }

        onSubmit && onSubmit(formData)?.then((message="success") => setButtonState(message)).catch((e) => {
            // console.error("ERROR", e);
            setButtonState(e);
            return;
        });
    };

    const addDisabledState = (children:ReactNode):ReactNode => {
        return Children.toArray(children).filter((child) => child !== null).map((child, index) => {
            if (!React.isValidElement(child)) {
                return child;
            }

            const componentName = (child.type as {componentName?: string}).componentName;

            const props:{[key: string]: any} = {disabled: buttonState !== "normal" || disabled || child.props.disabled, readOnly: readOnly};


            const childzFurtherChildren = child.props.children ?
                addDisabledState(child.props.children) :
                undefined;


            if (componentName === "FormButtonGroup") {
                // Change to LoadingButton group.
                props.type = "submit";
                props.state = buttonState;
                props.resetButton = () => setButtonState("normal");
                props.id = `formButtonGroup-${index}-${key}`;
                props.successDismissible = successDismissible;
                props.successText = successText;
            }
            if (componentName === "FormButton") {
                // Add submit stuff to buttons.
                props.type = "submit";
                props.state = buttonState;
                props.id = `formButton-${index}-${key}`;
                props.resetButton = () => setButtonState("normal");
                props.successDismissible = successDismissible;
                props.successText = successText;
            }

            return childzFurtherChildren ?
                React.cloneElement(child, props, childzFurtherChildren) :
                React.cloneElement(child, props);
        });
    };

    return (
        <form key={key} onSubmit={handleSubmit} className={className} style={{paddingTop: "10px", ...sx}} noValidate ref={formRef as any}>
            <Stack spacing={spacing}>
                <>
                    {addDisabledState(children)}
                    {onBackPress ?
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Link onClick={onBackPress}>Back</Link>
                            <LoadingButton display={button ? undefined : "none"} {...{successDismissible, successText, buttonRef, disabled}} width="max-content" variant="contained" key={"loadingButton"} resetButton={() => setButtonState("normal")} type={"submit"} state={buttonState} text={submitText}/>
                        </Stack> :
                        <LoadingButton display={button ? undefined : "none"} {...{successDismissible, successText, buttonRef, disabled}} sx={{minWidth: "100px"}} variant="contained" key={"loadingButton"} resetButton={() => setButtonState("normal")} type={"submit"} state={buttonState} text={submitText}/>
                    }
                </>
            </Stack>
        </form>
    );
});

export default Form;
