import {ListItemText, Stack, Chip, ListItemSecondaryAction, Typography, Button, ListItemButton} from "@mui/material";
import {deleteField, QueryConstraint} from "firebase/firestore";
import {camelCase, CohortData, convertDate, DataViewerFilterView, defaultStudentWorkflow, ERRORTEXTCOLOR, FilterObject, getUniqueId, StudentPlacementData, useNewInstitutePlacementList, UserData, WorkflowStage} from "placementt-core";
import {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {OrganisationContext, UserContext} from "../App";
import {RenderPlacementFlags} from "../Util/visualUtil";
import useWindowDimensions from "../Util/util";
import DataViewer from "./DataViewer";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";

// uid only provided if own placements. Provided on student dashboard

type Params = {
    id?: string,
    title: string,
    sx?: {[key:string]: unknown},
    queryConstraint?: QueryConstraint[],
    filters?: {[key:string]: unknown},
    urlRef?: string
    cohort?: CohortData|"all",
    inProgress?: boolean,
    viewType?: "list"|"table",
    uid?: string,
}


export default function PlacementList({inProgress, sx, cohort, uid}:Params) {
    const {width} = useWindowDimensions();
    const navigate = useNavigate();

    const user = useContext(UserContext) as UserData;
    const cohorts = useContext(OrganisationContext)?.cohorts;
    const firebaseQuery = new FirebaseQuery();
    const viewPlacementLocation = user.product === "students" ? `/${user.product}/placements/` : user.userType === "Staff" ? `/${user.product}/cohorts/placements/` : "../placements/";

    const filterNames: FilterObject = {
        reqUserType: {
            label: "Required user",
            type: "dropdown",
            values: {
                Staff: "Staff",
                Students: "Student",
                Provider: "Provider",
                Parent: "Parent/Guardian",
            },
        },
        riskAssessment: {
            label: "Risk assessment",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
        },
        dbsCheck: {
            label: "DBS check",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
        },
        insurance: {
            label: "Insurance",
            values: {awaitingReview: "Requires review", true: "Verified", false: "Not uploaded"},
            type: "dropdown",
        },
    };

    if (cohort !== "all") {
        filterNames["status" as keyof typeof filterNames] = {
            label: "Status",
            type: "dropdown",
            values: Object.fromEntries(cohort?.workflow?.map((stage) => [stage.id, stage.name]) || []),
        };
    }

    const saveFilterView = async (type: "me"|"everyone", saveFilters: DataViewerFilterView) => {
        if (cohort === "all") return;
        const updatePath = type === "me" ? ["users", user.id] : ["cohorts", cohort?.id as string];

        const updateData = type === "me" ? {[`savedDataViewerFilters.${cohort?.id}.cohortPlacements.${camelCase(saveFilters.title)+"_"+getUniqueId(cohort?.savedDataViewerFilters?.cohortPlacements || {})}`]: saveFilters} :
            {[`savedDataViewerFilters.cohortPlacements.${camelCase(saveFilters.title)+"_"+getUniqueId(cohort?.savedDataViewerFilters?.cohortPlacements || {})}`]: saveFilters};

        await firebaseQuery.update(updatePath, updateData);
    };

    const deleteFilterView = async (savedFilter: string) => {
        if (cohort === "all") return;

        await firebaseQuery.update(["users", user.id], {[`savedDataViewerFilters.${cohort?.id}.cohortPlacements.${savedFilter}`]: deleteField()});
        await firebaseQuery.update(["cohorts", cohort?.id as string], {[`savedDataViewerFilters.cohortPlacements.${savedFilter}`]: deleteField()});
    };

    const includesParentStage = typeof cohort === "object" && cohort.workflow.some((stage) => stage.userType === "Parent");

    let savedFilters:{[key: string]: DataViewerFilterView}|undefined = undefined;

    if (inProgress && cohort !== "all") {
        savedFilters = {
            allData: {
                title: "All Placements",
                sort: "Student Forename - Asc",
                permanent: true,
            },
            awaitingEmployer: {
                title: "Awaiting Employer",
                permanent: true,
                filters: {
                    reqUserType: {
                        label: "Required user",
                        type: "dropdown",
                        values: {
                            Staff: "Staff",
                            Students: "Student",
                            Provider: "Provider",
                            Parent: "Parent/Guardian",
                        },
                        value: "Provider",
                    },
                },
            },
        };

        if (includesParentStage) {
            savedFilters = {
                ...savedFilters,
                awaitingParent: {
                    permanent: true,
                    title: "Awaiting Parent",
                    filters: {
                        reqUserType: {
                            label: "Required user",
                            type: "dropdown",
                            values: {
                                Staff: "Staff",
                                Students: "Student",
                                Provider: "Provider",
                                Parent: "Parent/Guardian",
                            },
                            value: "Parent",
                        },
                    },
                },
            };
        }

        savedFilters = {...savedFilters,
            ...cohort?.savedDataViewerFilters?.cohortPlacements,
            ...user.savedDataViewerFilters?.[cohort?.id as string]?.cohortPlacements,
        };
    }

    return (
        <DataViewer
            key={`${inProgress ? "inProgress" : "completed"}PlacementList`}
            id={inProgress ? "inProgress" : "completed"}
            savedFilters={savedFilters}
            onDeleteSavedFilter={deleteFilterView}
            onSaveFilters={saveFilterView}
            columns={["student", "studentEmail", "name", "startDate", "endDate", "status"]}
            columnTemplate={{
                student: (val:any, row: StudentPlacementData) => (row.studentForename ? `${row.studentForename} ${row.studentSurname}` : <span style={{color: ERRORTEXTCOLOR}}>Student deleted</span>),
                status: (val:any, row: {cohort?: string}) => ((row.cohort ? (cohorts[row.cohort]?.workflow as WorkflowStage[]) : defaultStudentWorkflow)).find((s: WorkflowStage) => s.id === val)?.name,
                studentEmail: (val:any, row: StudentPlacementData) => row.studentEmail,
                startDate: (val: string) => convertDate(val, "visual"),
                endDate: (val: string) => convertDate(val, "visual"),
            }}
            filterNames={inProgress ? filterNames : undefined}
            actions={user.userType === "Staff" && inProgress && <Button size="small" sx={{mr: "0px !important", p: 0.5, pt: 0.2, pb: 0.2}} onClick={() => navigate(`/${user.product}/create`)}>Add placement</Button>}
            title={inProgress ? "In progress" : "Completed"}
            {...{sx}}
            disableSelection
            onItemClick={(key) => navigate(viewPlacementLocation+key)}
            hook={(e) => useNewInstitutePlacementList({user, filters: e.filters, uid, initialSearch: e.initialSearch, initialSort: e.initialSort, cohort: (!cohort || cohort === "all") ? undefined : cohort.id, inProgress, view: "list", ql: 10})}
            noResultsText={user.userType === "Students" ? <Typography sx={{textWrap: "balance"}}>No placements found. <Link to={`/${user.product}/create`} style={{textDecoration: "underline"}}>Click here</Link> to create one or click "Create" in the sidebar.</Typography> : undefined}
            onSearch
            listViewRenderer={(key: string, item: StudentPlacementData&{student:UserData}) => {
                const workflow = item.cohort ? cohorts[item.cohort]?.workflow : defaultStudentWorkflow;
                const status = item.status;

                if (!workflow) {
                    return <></>;
                }

                const wStage = workflow?.find((obj:WorkflowStage) => obj.id === status);


                return (
                    <ListItemButton key={key} divider id={key} onClick={() => navigate(viewPlacementLocation+key)}>
                        <ListItemText
                            primary={<Stack spacing={0}>
                                {width <= 500 && wStage &&
                                    <Stack height={"100%"} direction={"row"} sx={{justifyContent: "space-between"}}>
                                        {inProgress && <Chip label={wStage.name} color="primary" sx={{width: "max-content", margin: "8px !important"}}/>}
                                        <RenderPlacementFlags placement={item} workflow={workflow} icon/></Stack>}
                                <strong>{user.id === item.uid ? item.name : (item.studentForename ? `${item.studentForename} ${item.studentSurname}` : <span style={{color: ERRORTEXTCOLOR}}>Student deleted</span>)}</strong>
                                {user.id === item.uid || <span>{item.name} - {item.providerEmail}</span>}
                                <span>{item["address-line1"]} - {item.locality} - {item.postal_code}</span>

                            </Stack>}
                            secondary={`${item.startDate} - ${item.endDate}`}/>
                        {width > 500 && wStage && <ListItemSecondaryAction sx={{height: "100%"}}>

                            <Stack height={"100%"} sx={{justifyContent: "space-between"}}>
                                {inProgress && <Chip label={wStage.name} color="primary" sx={{width: "max-content", margin: "8px"}}/>}
                                <RenderPlacementFlags placement={item} workflow={workflow} icon/>
                            </Stack>
                        </ListItemSecondaryAction>}
                    </ListItemButton>
                );
            }}/>
    );
}

