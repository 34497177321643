import {useEffect, useState} from "react";
import {Descendant} from "slate";
import jsPDF from "jspdf";

export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (width <= 540);
};


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export const serialiseSlate = (nodes: Descendant[]) => {
    return nodes.map((n:any) => {
        if (n.type === "paragraph") {
            const text=[];
            for (let i = 0; i < n.children.length; i++) {
                const item = n.children[i];
                let subtext = item.text || <br/>;
                if (item.underline) {
                    subtext = <u>{subtext}</u>;
                }
                if (item.bold) {
                    subtext = <strong>{subtext}</strong>;
                }
                if (item.italic) {
                    subtext = <i>{subtext}</i>;
                }
                text.push(subtext);
            }
            return <p>{text}</p>;
        }
    });
};


export function imageToPdf(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        // Check if the file is an image
        if (!file.type.startsWith("image/")) {
            reject(new Error("The provided file is not an image"));
            return;
        }

        // Create a new Image object
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            // Create a canvas and draw the image on it
            const canvas = document.createElement("canvas");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const ctx = canvas.getContext("2d")!;
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Convert canvas to base64
            const imageData = canvas.toDataURL("image/jpeg");

            // Create a new jsPDF instance
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF({
                orientation: img.width > img.height ? "landscape" : "portrait",
                unit: "px",
                format: [img.width, img.height],
            });

            // Add image to PDF
            pdf.addImage(imageData, "JPEG", 0, 0, img.width, img.height);

            // Output PDF as base64
            const base64String = pdf.output("datauristring").split(",")[1];

            // Replace the MIME type to 'application/pdf'
            resolve(base64String.replace("data:application/octet-stream", "data:application/pdf"));
        };

        img.onerror = (error) => {
            reject(error);
        };
    });
}

export const formatPrice = (price: number, noSign?: boolean) => {
    const pricefromPence = (price/100).toString();
    const pounds = pricefromPence.split(".")?.[0];
    const pence = pricefromPence.split(".")?.[1];

    const penceFormatted = pence ?
        pence.length === 2 ? pence : pence.length > 2 ? pence.substring(0, 2) : (pence + "0") : "00";

    const finalCost = `${pounds}.${penceFormatted}`;
    return noSign ? finalCost : `£${finalCost}`;
};


export function stringToRGBColor(str: string): string {
    // Hash the string to generate a numeric value
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Use the hash to generate RGB values with ranges to avoid unpleasant colors
    const r = (hash & 0xFF) % 156 + 100; // Range: 50-205
    const g = ((hash >> 8) & 0xFF) % 156 + 100; // Range: 50-205
    const b = ((hash >> 16) & 0xFF) % 156 + 100; // Range: 50-205

    return `rgb(${r}, ${g}, ${b})`;
}


export const renderSlate = (text: Descendant[]) => {
    return (text.map((element: any) => {
        if (element.type === "paragraph") {
            return <p>{element.children.map((child: {text: string}) => <span>{child.text}</span>)}</p>;
        }
        if (element.type === "link") {
            return <a href={element.href} target={"_blank"}>{element.children.map((child: {text: string}) => <span>{child.text}</span>)}</a>;
        }
    }));
};
