import {Button, Collapse, Divider, Grid, Icon, Stack, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import FileDropzone from "../../../Components/FileDropzone/FileDropzone";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ArrowBack, CheckCircleOutline} from "@mui/icons-material";
import {executeCallable, PlacementReviewDetails, PRIMARY_COLOUR, uploadFiles} from "placementt-core";
import {ChangeEvent, useEffect, useState} from "react";
import styled from "styled-components";
import Form from "../../../Components/Form";
import {Popup} from "../../../Components/Popup";
import {imageToPdf} from "../../../Util/util";
import Alert from "../../../Components/Alert";

type Params = {
    visible: boolean,
    onComplete?: () => void,
    back?: () => void,
    providerContactId: string,
    pId?: string,
    uploaded?: boolean,
    expiry?: string,
    type?: "fade"|"popup",
    placement?: PlacementReviewDetails
}

export const FileUploaded = styled(Stack)`
  height: 100px;
  width: 300px;
  border: 2px grey solid;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export default function UploadProviderInsurance({uploaded, expiry, placement, visible, onComplete, back, providerContactId, pId, type="fade"}: Params) {
    const [fileUploaded, setFileUploaded] = useState<boolean>(uploaded || false);
    const [skipDocUpload, setSkipDocUpload] = useState<boolean>();
    const [expiryDate, setExpiryDate] = useState<string>();

    useEffect(() => {
        setFileUploaded(uploaded || false);
    }, [uploaded]);

    const uploadELI = async (e: {eli: any[], expiry: string}|string) => {
        if (!fileUploaded && typeof e === "object") {
            const splitName = e.eli[0].name.split(".");
            const suffix = splitName[splitName.length - 1];

            await uploadFiles(suffix === "pdf" ? e.eli[0] : await imageToPdf(e.eli[0]), `insurance/${providerContactId}.pdf`, "application/pdf");
        }
        await executeCallable("insurance-add", {
            pId: pId,
            providerContactId: providerContactId,
            reason: typeof e === "string" ? e : undefined,
            expiry: typeof e === "object" ? e.expiry : undefined})
            .then(() => {
                onComplete && onComplete();
            });
        if (skipDocUpload) {
            setSkipDocUpload(false);
        }
        setFileUploaded(true);
    };

    const InsuranceForm = <Form onSubmit={(e) => uploadELI(e as {eli: any[], expiry: string})} submitText={"Submit"}>

        <Typography variant='subtitle1' textAlign={"center"}>To take on a work experience student, you must have employer's liability insurance. We will not share this beyond the schools that organise placements with you.</Typography>

        <Grid item>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {fileUploaded ?
                        <Stack alignItems='center' spacing={1}>
                            <FileUploaded spacing={1}>
                                <Icon fontSize="large"><CheckCircleOutline fontSize="large" color="success"/></Icon>
                                <Typography>File uploaded</Typography>
                            </FileUploaded>
                        </Stack> :
                        <FileDropzone name={"eli"} required accept={"application/pdf, image/png, image/jpeg"} label={"Upload Employers liability insurance"} />}
                </Grid>
                <Grid item xs={12}>
                    <InputGroup onChange={(e: ChangeEvent<HTMLInputElement>) => setExpiryDate(e.target.value)} required value={expiry} id="expiry" type={"date"} label="Insurance expiry" name="expiry" />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={Boolean(expiryDate && ((expiryDate || "") < (placement?.startDate || "")))}>
                        <Alert severity="warning">Your insurance expires before the placement starts. You can still upload now, and we will ask for an updated form before the placement starts.</Alert>
                    </Collapse>
                </Grid>
                <Button onClick={() => setSkipDocUpload(true)} sx={{p: 1, pt: 0, pb: 0, margin: "auto", mt: 2}} variant="text">I can't provide this</Button>
            </Grid>
        </Grid>
        <Popup title={"Skip insurance upload"} open={Boolean(skipDocUpload)} onClose={() => setSkipDocUpload(undefined)}>
            <Form onSubmit={async (e) => await uploadELI(e.reason as string).then(() => setSkipDocUpload(false))} functionType="async">
                <Stack>
                    <Typography>If you are unable to provide insurance, the school may follow up directly, and this may impact your ability to take on a work placement.</Typography>
                    <Divider/>
                    <InputGroup required label={"Please provide a reason for skipping"} name={"reason"}/>
                </Stack>
            </Form>
        </Popup>
    </Form>;


    if (type === "fade") {
        return (
            <FadeInBox card visible={visible} cardTitle={[
                back && <IconButtonPop sx={{position: "absolute"}} onClick={back} responsive={false} title={"Back"}>
                    <ArrowBack />
                </IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} margin={"auto"} variant={"h4"}>Upload Insurance</Typography>]
            }>
                {InsuranceForm}
            </FadeInBox>
        );
    }

    return (
        <Popup open={visible} title={"Upload employers liability insurance"} onClose={() => back && back()}>
            {InsuranceForm}
        </Popup>
    );
}
